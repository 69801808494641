import React, { Component, ReactComponentElement } from "react";
import { getClassesFromBools } from "../../utilities/helperFunctions";

interface SwitchComponentProps {
  label: string|ReactComponentElement<any>;
  checked: boolean;
  onChange: () => void;
  onClick?: (e?: any) => void;
  disabled?: boolean;
  extraLabelClass?: string;
}

class SwitchComponent extends Component<SwitchComponentProps> {
  render() {
    const { label, checked, disabled, extraLabelClass, onChange, onClick } = this.props;

    return (
      <div className={`switch__wrapper ${getClassesFromBools({ disabled })}`}>
        <label
          title={label as string}
          className={`switch__wrapper__label ${extraLabelClass ?? ''}`}
        >
          {label}
        </label>
        <label className="switch__wrapper__switch-button">
          <input
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            onClick={onClick}
          />
          <span className="slider round"></span>
        </label>
      </div>
    );
  }
}

export default SwitchComponent;
