import axios from "axios";
import _ from "lodash";
import { cookies } from '../utilities/utilservice'

import { commonheaders } from "../../utilities/apiConfig";
import { endpoints } from "../../utilities/endpoints";
import { urls } from "../../utilities/urls";
import { deleteLocalStorage, setLocalStorage, setTokenCookies } from "../utilities/utilservice";
import {logoutFromAPI} from "../userservice";
import {extractDomainFromHostname} from "../partner/partnerService";

export function* getJWTToken(data) {
  let body = {
    userName: data.userName,
    password: data.password,
  };

  if (data.sessionId != null) {
    body.session_id = data.sessionId;
  }

  return yield axios
    .post(
      `${urls.baseApiURL}${endpoints.getAuthToken}`,
      JSON.stringify(body),
      commonheaders
    )
    .then((response) => {
      if (_.has(response.data, "JWT") && data.sessionId == null) {
        setTokenCookies(response.data.JWT);
      }
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export function* getGoogleLoginJWTToken(code) {
  return yield axios
    .get(
      `${urls.baseApiURL}${endpoints.getGoogleLoginAuthToken}?code=${code}`,
      commonheaders
    )
    .then((response) => {
      if (_.has(response.data, "JWT")) {
        setTokenCookies(response.data.JWT);
      }
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export async function loginWithGoogle() {
  return await axios
    .get(
        `${urls.baseApiURL}${endpoints.loginWithGoogle}`,
        commonheaders
    ).then((response) => {
      return response.data?.data;
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      }
      console.log(error);
    });
}

export function* getZohoCode(code) {
  return yield axios
    .get(
      `${urls.baseApiURL}${endpoints.getZohoCheck}${code}`,
      commonheaders
    ).then((response) => {
    return true;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export function* getPipedriveCode(code) {
  return yield axios
    .post(
      `${urls.baseApiURL}${endpoints.getPipedriveCode}${code}`,
      commonheaders
    ).then((response) => {
    return true;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export function* getHubspotCode(code) {
  return yield axios
    .post(
      `${urls.baseApiURL}${endpoints.getHubspotCode}${code}`,
      commonheaders
    ).then((response) => {
    return true;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export function* getGoogleMeetCode(code) {
  return yield axios
    .post(
      `${urls.baseApiURL}${endpoints.getGoogleMeetCode}${code}`,
      commonheaders
    ).then((response) => {
    return true;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export function* getMicrosoftTeamsCode(code) {
  return yield axios
    .post(
      `${urls.baseApiURL}${endpoints.getMicrosoftTeamsCode}${code}`,
      commonheaders
    ).then((response) => {
    return true;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export function saveSalesforceCode(code) {
  return axios
    .post(
      `${urls.baseApiURL}${endpoints.salesforceAuthSuccess}`,
      JSON.stringify({ code }),
      commonheaders
    ).catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export function* getDynamicsCode(code) {
  const url = `${urls.baseApiURL}${endpoints.getDynamicsCheck}?code=${code}`;
  return yield axios
      .get(
          url,
          commonheaders
      ).then((response) => {
        return true;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
}

export async function getDynamicsCodeAsync(code) {
  const url = `http://localhost:8000/api/${endpoints.getDynamicsCheck}?code=${code}`;
  return await axios
      .get(
          url,
          commonheaders
      ).then((response) => {
        return true;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
}


export function* getGoogleDriveFilesService(folderId) {
  const listEndPoint =
    folderId !== null
      ? `${endpoints.getGoogleDriveFiles}?parent_id=${folderId}`
      : `${endpoints.getGoogleDriveFiles}`;
  return yield axios
    .get(`${urls.baseApiURL}${listEndPoint}`, commonheaders)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export function* getGoogleDriveDownloadService(data, type) {
  return yield axios
    .post(
      `${urls.baseApiURL}${endpoints.getGoogleDownload}/${type}`,
      JSON.stringify(data),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export function* getCheckGoogleDriveLogin(code) {
  return yield axios
    .get(
      `${urls.baseApiURL}${endpoints.getGoogleDriveLoginCheck}${code}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export function* getGoogleDriveLogOutService() {
  return yield axios
    .delete(`${urls.baseApiURL}${endpoints.getGoogleDriveFiles}`, commonheaders)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export function* isAuthenticated() {
  let response = yield axios.get(
    `${urls.baseApiURL}${endpoints.verifyUser}`,
    commonheaders
  );

  if (response.data) {
    return yield response.data;
  } else {
    return false;
  }
}

export const isUserLoggingIn = () => {
  return cookies.get('JWT_Token');
};

export const logoutUser = async () => {
  try {
    await logoutFromAPI();
  } catch (error) {
    console.error(error);
  }

  deleteLocalStorage("temp-token");
  deleteLocalStorage("login-token");
  deleteLocalStorage("isTempAccount");
  deleteLocalStorage("currentSelectedAccount");
  deleteLocalStorage("is_beta");

  switch (window.location.hostname) {
    case "app.pitchflow.com":
      cookies.remove("JWT_Token", { path: "/", domain: ".pitchflow.com" });
      cookies.remove("auth-token", { path: "/", domain: ".pitchflow.com" });
      break;
    case "app.meetric.com":
      cookies.remove("JWT_Token", { path: "/", domain: ".meetric.com" });
      cookies.remove("auth-token", { path: "/", domain: ".meetric.com" });
      break;
    case "dashboard.staging.pitchflow.io":
      cookies.remove("JWT_Token", { path: "/", domain: ".pitchflow.io" });
      cookies.remove("auth-token", { path: "/", domain: ".pitchflow.io" });
      break;
    case "app.acceptflow.com":
      cookies.remove("JWT_Token", { path: "/", domain: ".acceptflow.com" });
      cookies.remove("auth-token", { path: "/", domain: ".acceptflow.com" });
      break;
    case "app.acceptstack.com":
      cookies.remove("JWT_Token", { path: "/", domain: ".acceptstack.com" });
      cookies.remove("auth-token", { path: "/", domain: ".acceptstack.com" });
      break;
    case "localhost":
      cookies.remove("JWT_Token", { path: "/", domain: "localhost" });
      cookies.remove("auth-token", { path: "/", domain: "localhost" });
      break;
    default:
      cookies.remove("JWT_Token", { path: "/", domain: extractDomainFromHostname(window.location.hostname) });
      cookies.remove("auth-token", { path: "/", domain: extractDomainFromHostname(window.location.hostname) });
      break;
  }
  window.location.assign(urls.loginUrl);
};

export function* getLoginAsUser(userId) {
  return yield axios
    .post(`${urls.baseApiURL}${endpoints.loginAs}/${userId}`, commonheaders)
    .then((response) => {
      // if (_.has(response.data, "JWT")) {
      //   setTokenCookies(response.data.JWT, false);
      // }
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export function* loginToSessionUsingLink(link) {
  const url = `${urls.baseApiURL}${endpoints.loginAs}/${endpoints.session}/${link}`;

  try {
    const response = yield axios.post(url, commonheaders);
    return response.data;
  } catch (error) {
    if (error.response) {
      return { error: error.response.data };
    }
    return error;
  }
}

export function* getTokenBySessionId(sessionId) {
  return yield axios
    .get(
      `${urls.baseApiURL}${endpoints.loginSessions}/${sessionId}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export function* googleDriveLoginService() {
  return yield axios
    .get(`${urls.baseApiURL}${endpoints.loginWithGoogleDrive}`, commonheaders)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export function* allUsers() {
  return yield axios
    .get(`${urls.baseApiURL}${endpoints.getUsers}`, commonheaders)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export function* forgotPasswordServices(data) {
  return yield axios
    .post(
      `${urls.baseApiURL}${endpoints.forgotPassword}`,
      JSON.stringify({ email: data.email }),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export function* getShareLinkLogin(linkHash) {
  return yield axios
    .get(`${urls.baseApiURL}${endpoints.shareLinkLogin}/${linkHash}`, commonheaders)
    .then((response) => {
      if (_.has(response.data, "JWT")) {
        const token = response.data.JWT;

        setLocalStorage("share-link-token", token);
      }
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}
