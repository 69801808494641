import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

import { loginRoute } from "./constants";
import { mainRequestConfig } from "./requestConfig";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import PartnerLogin from "../pages/Authentication/PartnerLogin";
import Logout from "../pages/Authentication/Logout";
import SetPassword from "../pages/Authentication/SetPassword";
import Success from "../pages/Authentication/Success";
import PipedriveSuccess from '../pages/Authentication/PipedriveSuccess';
import HubspotSuccess from '../pages/Authentication/HubspotSuccess';
import ZohoSuccess from "../pages/Authentication/ZohoSuccess";
import SalesforceAuthCompleted from "../pages/Authentication/SalesforceAuthCompleted";
import Dynamics365Success from '../pages/Authentication/Dynamics365Success';
import GoogleMeetSuccess from '../pages/Authentication/GoogleMeetSuccess';
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import MicrosoftTeamsSuccess from '../pages/Authentication/MicrosoftTeamsSuccess';
import GoogleLoginSuccess from '../pages/Authentication/GoogleLoginSuccess';

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import SharedRecordingPage from "../pages/Analytics/SharedRecordingPage";
import SharedSnippetPage from "../pages/Analytics/SharedSnippetPage";
import VideoPlayerPage from "../pages/Analytics/VideoPlayerPage";
import ConversationInsights from "../pages/ConversationInsights";
import SnippetPage from "../pages/Analytics/SnippetPage";
import RecordingsDetailsNavigation from "../pages/RecordingsDetails/RecordingsDetailsNavigation";
import GptPrompt from "../pages/GptPrompt/index";
import RecordLiveMeeting from "../pages/RecordLiveMeeting";

const Analytics = React.lazy(() => import("../pages/Analytics/index"));
const Presentations = React.lazy(() => import("../pages/Presentations/index"));
const PresentationsCategory = React.lazy(
  () => import("../pages/Presentations/PresentationsCategory/index")
);
const Settings = React.lazy(() => import("../pages/Settings/index"));

const SingleCompany = React.lazy(
  () => import("../pages/Analytics/SingleCompany")
);
const SingleContact = React.lazy(
  () => import("../pages/Analytics/SingleContact")
);
const SingleDocument = React.lazy(
  () => import("../pages/Analytics/SingleDocument")
);
const SingleMeeting = React.lazy(
  () => import("../pages/Analytics/SingleMeeting")
);
const SinglePage = React.lazy(
  () => import("../pages/Analytics/SinglePage")
);
const SingleRoom = React.lazy(
  () => import("../pages/Analytics/SingleRoom")
);
const SingleTeam = React.lazy(
  () => import("../pages/Analytics/SingleTeam")
);
const SingleUser = React.lazy(
  () => import("../pages/Analytics/SingleUser")
);

const Meetings = React.lazy(() => import("../pages/Meetings"));
const InspirationLibrary = React.lazy(() => import("../pages/InspirationLibrary"));
const Rooms = React.lazy(() => import("../pages/Rooms"));
const Home = React.lazy(() => import("../pages/Home"));
const CompareView = React.lazy(() => import("../pages/CompareView"));
const AIAgentPage = React.lazy(() => import("../pages/AIAgent"));

axios.interceptors.request.use(
  function (config) {
    if (config.url === loginRoute) {
      return config;
    }

    return mainRequestConfig(config);
  },
  function (error) {
    return Promise.reject(error);
  }
);

const authProtectedRoutes = [
  /** Single company analytics */
  { path: "/company/:category/:companyId", component: SingleCompany },

  /** Single contact analytics */
  { path: "/contact/:category/:contactId", component: SingleContact },

  /** Single page analytics */
  { path: "/document/:documentId/page/:category/:pageId", component: SinglePage },

  /** Single document analytics */
  { path: "/document/:category/:documentId", component: SingleDocument },

  /** Single meeting analytics  */
  { path: "/meeting/:category/:sessionId", component: SingleMeeting },

  /** Single room analytics */
  { path: "/room/:category/:roomId", component: SingleRoom },

  /** Single team analytics */
  { path: "/team/:category/:teamId", component: SingleTeam },

  /** Single user analytics */
  { path: "/user/:category/:userId", component: SingleUser },

  /** Single recording player */
  { path: "/recording/:sessionId", component: VideoPlayerPage },

  { path: "/snippet/:snippetUuid", component: SnippetPage },

  //Pages
  // { path: "/insights", component: Analytics },
  // { path: "/documents", component: Presentations },
  {
    path: "/documents_import",
    component: Presentations,
    fromIframe: true,
  },
  { path: "/document/:category", component: Presentations },
  {
    path: "/document_import/:category",
    component: Presentations,
    fromIframe: true,
  },
  { path: "/settings", component: Settings },
  { path: "/meetings", component: Meetings },
  { path: "/inspiration-library", component: InspirationLibrary },
  { path: "/rooms", component: Rooms},
  { path: "/dashboard", component: Home },
  // { path: "/stats", component: CompareView },
  { path: "/conversation-search", component: ConversationInsights },
  { path: "/recordings", component: RecordingsDetailsNavigation },
  { path: "/gpt-prompt", component: GptPrompt },
  { path: "/record-live-meeting", component: RecordLiveMeeting },
  { path: "/agents/:agentId", component: AIAgentPage },
  { path: "/agents", component: AIAgentPage },
  {
    path: "/rooms_import",
    component: Rooms,
    fromIframe: true,
  },
  { path: "/room/:category", component: PresentationsCategory },
  {
    path: "/profile",
    exact: "true",
    component: () => <Redirect to="/settings/profile" />,
  },
  {
    path: "/account",
    component: () => <Redirect to="/settings/account" />,
  },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-500", component: Pages500 },
];

const commonRoutes = [
  { path: "/success", component: Success },
  { path: "/zoho/success", component: ZohoSuccess },
  { path: "/pipedrive/success", component: PipedriveSuccess },
  { path: "/google-login/success", component: GoogleLoginSuccess },
  { path: "/hubspot/success", component: HubspotSuccess },
  { path: "/google-meet/success", component: GoogleMeetSuccess },
  { path: "/ms-teams/success", component: MicrosoftTeamsSuccess },
  { path: "/salesforce/auth-success", component: SalesforceAuthCompleted },
  { path: "/salesforce/auth-completed", component: SalesforceAuthCompleted },
  { path: "/dynamics/success", component: Dynamics365Success },
  { path: "/partnerLogin", component: PartnerLogin },
  { path: ["/setPassword", "/reset-password"], component: SetPassword },
  { path: "/pages-404", component: Pages404 },
  { path: "/recording-share/:linkHash", component: SharedRecordingPage },
  { path: "/snippet-share/:linkHash", component: SharedSnippetPage },
];

export { authProtectedRoutes, publicRoutes, commonRoutes };
