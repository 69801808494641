import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { has } from 'lodash';

import {
  getAIAgents,
  getAIAgentsError,
  getAIAgentsSuccess,
  createAIAgent,
  createAIAgentError,
  createAIAgentSuccess,
  deleteAIAgent,
  deleteAIAgentError,
  deleteAIAgentSuccess,
  updateAIAgent,
  updateAIAgentError,
  updateAIAgentSuccess,
  setCurrentAIAgent,
  sendAIAgentRequest,
  sendAIAgentRequestSuccess,
  sendAIAgentRequestError,
  tryAIAgentRequest,
  tryAIAgentRequestSuccess,
  tryAIAgentRequestError,
  getAIAgentRequest,
  getAIAgentRequestSuccess,
  getAIAgentRequests,
  getAIAgentRequestsSuccess,
  getAIAgentRequestsError,
  getAIAgent,
  getAIAgentError,
  getAIAgentSuccess,
  getAIAgentRequestError,
  getAIAgentTypes,
  getAIAgentTypesError,
  getAIAgentTypesSuccess,
  getPlaybookForSession,
  getPlaybookForSessionSuccess,
  getPlaybookForSessionError,
  getAllPlaybooksForSession,
  getAllPlaybooksForSessionSuccess,
  getAllPlaybooksForSessionError,
  getAllDataInsightsForSession,
  getAllDataInsightsForSessionSuccess,
  getAllDataInsightsForSessionError,
} from './actions';

import {
  CREATE_AI_AGENT,
  DELETE_AI_AGENT,
  GET_AI_AGENTS,
  GET_AI_AGENT_REQUEST,
  GET_AI_AGENT_REQUESTS,
  SEND_AI_AGENT_REQUEST,
  TRY_AI_AGENT_REQUEST,
  UPDATE_AI_AGENT,
  GET_AI_AGENT,
  GET_AI_AGENT_TYPES,
  GET_PLAYBOOK_FOR_SESSION,
  GET_ALL_PLAYBOOKS_FOR_SESSION,
  GET_ALL_DATA_INSIGHTS_FOR_SESSION,
} from './actionTypes';

import { ICreateAIAgent } from './interface';
import {
  getAIAgents as getAIAgentsService,
  getAIAgent as getAIAgentService,
  createAIAgent as createAIAgentService,
  deleteAIAgent as deleteAIAgentService,
  updateAIAgent as updateAIAgentService,
  sendAIAgentRequest as sendAIAgentRequestService,
  tryAIAgentRequest as tryAIAgentRequestService,
  getAIAgentRequest as getAIAgentRequestService,
  getAIAgentRequests as getAIAgentRequestsService,
  getAllAIAgentTypes as getAllAIAgentTypesService,
  getPlaybookForSession as getPlaybookForSessionService,
  getAllPlaybooksForSession as getAllPlaybooksForSessionService,
  getAllDataInsightsForSession as getAllDataInsightsForSessionService,
} from '../../services/settings/AIAgent';

import { ResponseGenerator } from '../../services/userservice';

function* getAIAgentsHelper({
  payload,
}: ReturnType<typeof getAIAgents>) {
  const response: ResponseGenerator = yield call(
    getAIAgentsService,
    payload.accountId
  );
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getAIAgentsError(error));
    } else {
      yield put(getAIAgentsError('An error has occurred!!'));
    }
  } else {
    yield put(getAIAgentsSuccess(response.data));
  }
}

function* updateAIAgentHelper({
  payload,
}: ReturnType<any>) {
  const updatedAgent: ICreateAIAgent = {
    name: payload.agent.name,
    type: payload.agent.type,
    typeSettings: payload.agent.typeSettings,
    customPrompt: payload.agent.customPrompt,
    globalFilters: payload.agent.globalFilters,
    status: payload.agent.status,
  };

  const response: ResponseGenerator = yield call(
    updateAIAgentService,
    payload.accountId,
    payload.agent.id,
    updatedAgent
  );
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(updateAIAgentError(error));
    } else {
      yield put(updateAIAgentError('An error has occurred!!'));
    }
  } else {
    yield put(updateAIAgentSuccess(response.data.data));
    yield put(setCurrentAIAgent(response.data.data));

    payload?.onSuccess && payload.onSuccess(
      response.data.data,
      payload.sessionId ?? '',
      String(payload.accountId),
    );
  }
}

function* deleteAIAgentHelper({
  payload,
}: ReturnType<typeof deleteAIAgent>) {
  const response: ResponseGenerator = yield call(
    deleteAIAgentService,
    payload.accountId,
    payload.agentId
  );
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(deleteAIAgentError(error));
    } else {
      yield put(deleteAIAgentError('An error has occurred!!'));
    }
  } else {
    yield put(deleteAIAgentSuccess());
    yield put(getAIAgents(payload.accountId));
  }
}

function* createAIAgentHelper({
  payload,
}: ReturnType<typeof createAIAgent>) {
  const response: ResponseGenerator = yield call(
    createAIAgentService,
    payload.accountId,
    payload.agent
  );
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(createAIAgentError(error));
    } else {
      yield put(createAIAgentError('An error has occurred!!'));
    }
  } else {
    yield put(createAIAgentSuccess(response.data));
    yield put(setCurrentAIAgent(response.data.data));

    payload?.onSuccess && payload.onSuccess(
      response.data.data,
      payload.sessionId ?? '',
      String(payload.accountId),
    );
  }
}

function* sendAIAgentRequestHelper({ payload }: ReturnType<any>) {
  const response: ResponseGenerator = yield call(
    sendAIAgentRequestService,
    payload.accountID,
    payload.agentID,
    payload.sessionID
  );

  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(sendAIAgentRequestError(error));
    } else {
      yield put(sendAIAgentRequestError('An error has occurred!!'));
    }
  } else {
    yield put(sendAIAgentRequestSuccess(response.data.data));
  }
}

function* tryAIAgentRequestHelper({ payload }: ReturnType<typeof tryAIAgentRequest>) {
  const response: ResponseGenerator = yield call(
    tryAIAgentRequestService,
    payload.accountID,
    payload.agent,
    payload.sessionID
  );

  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(tryAIAgentRequestError(error));
    } else {
      yield put(tryAIAgentRequestError('An error has occurred!!'));
    }
  } else {
    yield put(tryAIAgentRequestSuccess(response.data.data));
  }
}

function* getAIAgentRequestHelper({
  payload,
}: ReturnType<typeof getAIAgentRequest>) {
  const response: ResponseGenerator = yield call(
    getAIAgentRequestService,
    payload.accountId,
    payload.agentId,
    payload.requestId
  );
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getAIAgentRequestError(error));
    } else {
      yield put(getAIAgentRequestError('An error has occurred!'));
    }
  } else {
    yield put(getAIAgentRequestSuccess(response.data.data));
  }
}

function* getAIAgentRequestsHelper({
  payload,
}: ReturnType<typeof getAIAgentRequests>) {
  const response: ResponseGenerator = yield call(
    getAIAgentRequestsService,
    payload.accountId,
    payload.agentId,
  );

  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getAIAgentRequestsError(error));
    } else {
      yield put(getAIAgentRequestsError('An error has occurred!'));
    }
  } else {
    yield put(getAIAgentRequestsSuccess(response.data.data));
  }
}

function* getAIAgentHelper({
  payload,
}: ReturnType<typeof getAIAgent>) {
  const response: ResponseGenerator = yield call(
    getAIAgentService,
    payload.accountId,
    payload.agentID
  );
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getAIAgentError(error));
    } else {
      yield put(getAIAgentError('An error has occurred!!'));
    }
  } else {
    yield put(getAIAgentSuccess(response.data.data));
  }
}

function* getAllAIAgentTypesHelper({
  payload,
}: ReturnType<typeof getAIAgentTypes>) {
  const response: ResponseGenerator = yield call(
    getAllAIAgentTypesService,
    payload,
  );
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getAIAgentTypesError(error));
    } else {
      yield put(getAIAgentTypesError('An error has occurred!!'));
    }
  } else {
    yield put(getAIAgentTypesSuccess(response.data.data));
  }
}

function* getPlaybookForSessionHelper({
 payload,
}: ReturnType<typeof getPlaybookForSession>) {
  const response: ResponseGenerator = yield call(
    getPlaybookForSessionService,
    payload.accountId,
    payload.sessionId,
  );

  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getPlaybookForSessionError(error));
    } else {
      yield put(getPlaybookForSessionError('An error has occurred!!'));
    }
  } else {
    yield put(getPlaybookForSessionSuccess(response.data.data));
  }
}

function* getAllPlaybooksForSessionHelper({
  payload,
}: ReturnType<typeof getAllPlaybooksForSession>) {
  const response: ResponseGenerator = yield call(
    getAllPlaybooksForSessionService,
    payload.accountId,
    payload.sessionId,
  );

  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getAllPlaybooksForSessionError(error));
    } else {
      yield put(getAllPlaybooksForSessionError('An error has occurred!!'));
    }
  } else {
    yield put(getAllPlaybooksForSessionSuccess(response.data.data));
  }
}

function* getAllDataInsightsForSessionHelper({
  payload,
}: ReturnType<typeof getAllDataInsightsForSession>) {
  const response: ResponseGenerator = yield call(
    getAllDataInsightsForSessionService,
    payload.accountId,
    payload.sessionId,
  );

  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getAllDataInsightsForSessionError(error));
    } else {
      yield put(getAllDataInsightsForSessionError('An error has occurred!!'));
    }
  } else {
    yield put(getAllDataInsightsForSessionSuccess(response.data.data));
  }
}

export function* watchGetAIAgentsHelper() {
  yield takeEvery(GET_AI_AGENTS, getAIAgentsHelper);
}

export function* watchCreateAIAgentHelper() {
  yield takeEvery(CREATE_AI_AGENT, createAIAgentHelper);
}

export function* watchDeleteAIAgentHelper() {
  yield takeEvery(DELETE_AI_AGENT, deleteAIAgentHelper);
}

export function* watchUpdateAIAgentHelper() {
  yield takeEvery(UPDATE_AI_AGENT, updateAIAgentHelper);
}

export function* watchSendAIAgentRequestHelper() {
  yield takeEvery(SEND_AI_AGENT_REQUEST, sendAIAgentRequestHelper);
}

export function* watchTryAIAgentRequestHelper() {
  yield takeEvery(TRY_AI_AGENT_REQUEST, tryAIAgentRequestHelper);
}

export function* watchGetAIAgentRequestHelper() {
  yield takeEvery(GET_AI_AGENT_REQUEST, getAIAgentRequestHelper);
}

export function* watchGetAIAgentRequestsHelper() {
  yield takeEvery(GET_AI_AGENT_REQUESTS, getAIAgentRequestsHelper);
}

export function* watchGetAIAgentHelper() {
  yield takeEvery(GET_AI_AGENT, getAIAgentHelper);
}

export function* watchGetAllAIAgentTypesHelper() {
  yield takeEvery(GET_AI_AGENT_TYPES, getAllAIAgentTypesHelper);
}

export function* watchGetPlaybookForSessionHelper() {
  yield takeEvery(GET_PLAYBOOK_FOR_SESSION, getPlaybookForSessionHelper);
}

export function* watchGetAllPlaybooksForSessionHelper() {
  yield takeEvery(GET_ALL_PLAYBOOKS_FOR_SESSION, getAllPlaybooksForSessionHelper);
}

export function* watchGetAllDataInsightsForSessionHelper() {
  yield takeEvery(GET_ALL_DATA_INSIGHTS_FOR_SESSION, getAllDataInsightsForSessionHelper);
}

function* AIAgentSaga() {
  yield all([
    fork(watchGetAIAgentsHelper),
    fork(watchCreateAIAgentHelper),
    fork(watchDeleteAIAgentHelper),
    fork(watchUpdateAIAgentHelper),
    fork(watchSendAIAgentRequestHelper),
    fork(watchTryAIAgentRequestHelper),
    fork(watchGetAIAgentRequestHelper),
    fork(watchGetAIAgentRequestsHelper),
    fork(watchGetAIAgentHelper),
    fork(watchGetAllAIAgentTypesHelper),
    fork(watchGetPlaybookForSessionHelper),
    fork(watchGetAllPlaybooksForSessionHelper),
    fork(watchGetAllDataInsightsForSessionHelper),
  ]);
}

export default AIAgentSaga;
