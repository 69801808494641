import { MenuPosition } from "react-select";

export function updateListInsertAtIndex<T>(
  list: T[],
  item: T,
  index: number
): T[] {
  if (index === -1) {
    return [...list, item];
  }
  return [...list.slice(0, index), item, ...list.slice(index + 1)];
}

export function flattenObjectsWithChildren(objects: any[], childrenPropName: string = 'children') {
  return objects.flatMap(object => [object, ...(object[childrenPropName] || [])]);
}

export function canUseAiAgents(user: any, account: any) {
  const isUserAdmin = account?.right_level === "admin";
  const canUserAccessAiAgents = user?.can_use_ai_agents;
  const canAnyTeamAccessAiAgents = user?.teams?.find(
    ({ can_use_ai_agents }: { can_use_ai_agents: boolean; }) => can_use_ai_agents
  );
  return isUserAdmin || canUserAccessAiAgents || canAnyTeamAccessAiAgents;
}

export const selectPropsForModal = {
  menuPortalTarget: document.body,
  menuPosition: 'fixed' as MenuPosition,
  styles: {
    menuPortal: (provided: any) => ({ ...provided, zIndex: 1051, }),
  },
};

export function createGroupedBools(obj: any = {}) {
  if (obj.any || obj.all) {
    throw new Error(`Setting the properties "any" or "all" not allowed on this object`);
  }
  const allProps: string[] = Object.keys(obj);
  return new Proxy(obj, {
    get(obj: any, prop, receiver) {
      if (prop === 'any') {
        return allProps.some(prop => obj[prop]);
      } else if (prop === 'all') {
        return !allProps.find(prop => !obj[prop]);
      } else {
        return obj[prop];
      }
    },
    set(obj, prop: string, value) {
      if (prop === 'any' || prop === 'all') {
        throw new Error(`Setting the properties "any" or "all" not allowed on this object`);
      }
      if (!allProps.includes(prop)) {
        allProps.push(prop);
      }
      obj[prop] = value;
      return true;
    },
  });
}

export function getClassesFromBools(object: any) {
  return Object.entries(object)
    .filter(([key, value]) => value)
    .map(([key]) => key)
    .join(' ');
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function cancellable(promise: Promise<any>) {
  let cancelled = false;

  return {
    resultPromise: promise.then(result => cancelled ? Promise.reject('Promise cancelled') : result),
    cancel: () => { cancelled = true; },
  };
}
