export const GET_VARIABLE = 'GET_VARIABLE';
export const GET_VARIABLE_SUCCESS = 'GET_VARIABLE_SUCCESS';
export const GET_VARIABLE_ERROR = 'GET_VARIABLE_ERROR';
export const CREATE_VARIABLE = 'CREATE_VARIABLE';
export const CREATE_VARIABLE_SUCCESS = 'CREATE_VARIABLE_SUCCESS';
export const CREATE_VARIABLE_ERROR = 'CREATE_VARIABLE_ERROR';
export const EDIT_VARIABLE = 'EDIT_VARIABLE';
export const EDIT_VARIABLE_SUCCESS = 'EDIT_VARIABLE_SUCCESS';
export const EDIT_VARIABLE_ERROR = 'EDIT_VARIABLE_ERROR';
export const DELETE_VARIABLE = 'DELETE_VARIABLE';
export const DELETE_VARIABLE_SUCCESS = 'DELETE_VARIABLE_SUCCESS';
export const DELETE_VARIABLE_ERROR = 'DELETE_VARIABLE_ERROR';