import { isEmpty } from 'lodash';
import { getPartnerByHash } from './partnerApi';
import {
  acceptFlowURL,
  acceptStackURL,
  connectAppDevURL,
  connectAppStagingURL,
  connectAppURL,
  isAcceptFlowConnectURL,
  isAcceptFlowURL,
  isLocalURL, isStagingConnectURL,
  localURL,
  meetricURL,
  isLocalConnectURL, isAcceptStackURL
} from '../../utilities/urls';

export function getPartnerFromLocal(): Partner|null {
  const partner = localStorage.getItem('partner');

  return isEmpty(partner) ? null : JSON.parse(partner!);
}

export function setPartnerToLocal(partner: Partner|null) {
  localStorage.setItem('partner', JSON.stringify(partner));
}

export interface PartnerData {
  id: string;
  icon?: string;
  name: string;
  logo_favicon?: string;
  logo_main?: string;
  domain_dashboard?: string;
  domain_switchboard?: string;
  integrations_blacklist: string[] | null;
  theme?: string;
  created_at: string;
}

export interface Partner {
  id: string;
  icon?: string;
  name: string;
  logoFavicon?: string;
  logoMain?: string;
  domainDashboard?: string;
  domainSwitchboard?: string;
  integrationsBlacklist: string[] | null;
  theme?: string;
  createdAt: string;
}

export const createPartnerFromData = (data: PartnerData): Partner =>
{
  return {
    id: data.id,
    icon: data.icon,
    name: data.name,
    logoFavicon: data.logo_favicon,
    logoMain: data.logo_main,
    domainDashboard: data.domain_dashboard,
    domainSwitchboard: data.domain_switchboard,
    integrationsBlacklist: data.integrations_blacklist,
    createdAt: data.created_at,
  };
}

export const extractPartnerHashFromUrlState = (
  urlState: string
): string | null => {
  const match = urlState.match(/partner=([^&]+)/);
  return match ? match[1] : null;
};

export const isConnectAppOrigin = () => {
  try {
    const connectAppOrigins = [
      connectAppDevURL,
      connectAppStagingURL,
      connectAppURL,
    ];

    return connectAppOrigins.includes(window.location.origin);
  } catch (error) {
    console.error('Invalid URL:', error);
    return false;
  }
}

export const isMeetricOrigin = () => {
  try {
    const meetricOrigins = [
      meetricURL,
      acceptStackURL,
      acceptFlowURL,
    ];

    return meetricOrigins.includes(window.location.origin);
  } catch (error) {
    console.error('Invalid URL:', error);
    return false;
  }
}

export async function getBaseClientUrl(urlState: string | null) {
  let redirectBaseUrl = meetricURL;
  if (isAcceptStackURL || isStagingConnectURL) {
    redirectBaseUrl = acceptStackURL;
  } else if (isAcceptFlowURL || isAcceptFlowConnectURL) {
    redirectBaseUrl = acceptFlowURL;
  } else if (isLocalURL || isLocalConnectURL) {
    redirectBaseUrl = localURL;
  }

  if (!urlState) {
    return redirectBaseUrl;
  }

  const partnerHash = extractPartnerHashFromUrlState(urlState);
  if (partnerHash) {
    const partner = await getPartnerByHash(partnerHash);

    if (
      urlState
      && partner
      && partner.domainDashboard
    ) {
      redirectBaseUrl = `https://${partner.domainDashboard}`;
    }
  }

  return redirectBaseUrl;
}

export function extractHostnameFromURL(url: string) {
  try {
    const { hostname } = new URL(url.trim());

    if (!hostname.includes('.')) return hostname;

    const parts = hostname.split('.');

    const mainDomain = parts.slice(-2).join('.');
    if (parts.length > 2) return parts.slice(0, -1).join('.');

    return mainDomain;

  } catch (error: any) {
    console.error("Invalid URL:", error.message);
    return null;
  }
}

export function extractDomainFromHostname(hostname: string): string | null {
  const parts = hostname.split('.');

  if (parts.length < 2) return null;

  return `.${parts.slice(-2).join('.')}`;
}
