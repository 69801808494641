import {
  ANALYTICS_STATS_WIDGET_TYPES, DropdownOption,
  KEYWORD_STATUSES,
  STATISTIC_WIDGET_TYPES
} from "../../../pages/Home/utils/constants";
import { IAssociatedTeam, IAssociatedUser } from '../../../store/interface';
import { Filters } from '../../../store/layout/interface';
import {
  averageInterruptions,
  avgInteractionTime,
  callOutcomes,
  conversationShifts,
  customerInTab,
  customerStories,
  deals,
  directShare,
  faceToFace,
  interactionTime,
  longestMonologues,
  meetingScore,
  numberOfInteractions,
  questions,
  scheduledInteractions,
  screenShare,
  slidesShown,
  talkListenRatio,
  wordsPerMinute,
} from './WidgetNames';
import { IWidgetOptions } from '../WidgetLayoutItem/constants';

export type ChartType = "total" | "compare" | "period" | "unit";

export const emptyGlobalFiltersObj: IGlobalFilters = {
  recording_types: [],
  campaign_ids: [],
  global_meeting_types: [],
  global_user_ids: [],
  global_team_ids: [],
  calls_outcome: [],
  deals_status: [],
  global_start: "",
  global_end: "",
  global_period: "",
}

export const CHART_TYPE_GROUPS: Record<ChartType, Array<{ id: number; label: string; value: string }>> = {
  total: [{ id: 0, label: "All", value: "all" }],
  compare: [
    {
      id: 1,
      label: "User",
      value: "user",
    },
    {
      id: 2,
      label: "Team",
      value: "team",
    },
    {
      id: 3,
      label: "Value",
      value: "value",
    },
    // {
    //   id: 6,
    //   label: "Unique contact",
    //   value: "email",
    // },
    // {
    //   id: 7,
    //   label: "Unique domain",
    //   value: "domain",
    // },
  ],
  period: [
    {
      id: 3,
      label: "Daily",
      value: "daily",
    },
    {
      id: 4,
      label: "Weekly",
      value: "weekly",
    },
    {
      id: 5,
      label: "Monthly",
      value: "monthly",
    },
  ],
  unit: [
    {
      id: 1,
      label: "Value (Total)",
      value: "",
    }, {
      id: 2,
      label: "Value / Interaction (AVG)",
      value: "interaction",
    }, {
      id: 3,
      label: "Value / Hourly (AVG)",
      value: "hourly",
    },
    {
      id: 4,
      label: "Deal won / Total deals (%)",
      value: "deal_to_deal_interactions",
    },
    {
      id: 5,
      label: "Deal won / Total interactions (%)",
      value: "deal_to_total_interactions",
    },
  ]
};

export const SENTENCE_OPTIONS_SPEAKER_TYPES = [
  {
    id: 1,
    label: "All",
    value: "all",
  },
  {
    id: 2,
    label: "Buyer",
    value: "buyer",
  },
  {
    id: 3,
    label: "Seller",
    value: "seller",
  },
];

export const CHART_TYPES = [
  {
    id: 1,
    label: "Total",
    value: "total",
    isBarClickable: false,
  },
  {
    id: 2,
    label: "Compare",
    value: "compare",
    isBarClickable: true,
  },
  {
    id: 3,
    label: "Period",
    value: "period",
    isBarClickable: true,
  },
];

export const STATS_QUESTIONS: SentenceType[] = [
  { id: "QQ", sentence_type: "Qualifying" },
  { id: "QO", sentence_type: "Open-ended" },
  { id: "QC", sentence_type: "Closed-ended" },
  { id: "QP", sentence_type: "Probing" },
  { id: "QS", sentence_type: "Solution-focused" },
  { id: "QT", sentence_type: "Trial close" },
  { id: "QCm", sentence_type: "Commitment" },
  { id: "QF", sentence_type: "Follow-up" },
  { id: "QCf", sentence_type: "Confirmation" },
];

export const STATS_OBJECTIONS: SentenceType[] = [
  { id: "OP", sentence_type: "Price" },
  { id: "OV", sentence_type: "Value" },
  { id: "OB", sentence_type: "Budget" },
  { id: "OT", sentence_type: "Timing" },
  { id: "OA", sentence_type: "Authority" },
  { id: "ON", sentence_type: "Need" },
  { id: "OI", sentence_type: "Implementation" },
  { id: "OPS", sentence_type: "Product or service" },
];

export const STATS_STATEMENTS: SentenceType[] = [
  { id: "SF", sentence_type: "Factual" },
  { id: "SO", sentence_type: "Opinion" },
  { id: "SH", sentence_type: "Hypothetical" },
  { id: "SE", sentence_type: "Empathetic" },
  { id: "SR", sentence_type: "Rapport-building" },
  { id: "SC", sentence_type: "Customer story" },
];

export const STATS_SENTIMENTS: SentenceType[] = [
  { id: "aiSentimentPositive", sentence_type: "Positive" },
  { id: "aiSentimentNegative", sentence_type: "Negative" },
  { id: "aiSentimentNeutral", sentence_type: "Neutral" },
];

export const STATS_PRODUCT_ISSUES: SentenceType[] = [
  { id: "aiProductIssue", sentence_type: "Product issues"},
  { id: "aiProductNotIssue", sentence_type: "Product non-issues"},
]

export const STATS_PAIN_POINTS: SentenceType[] = [
  { id: "aiIsPainPoint", sentence_type: "Customer concerns"},
]

export const STATS_NEW_NEEDS: SentenceType[] = [
  { id: "aiIsNewNeeds", sentence_type: "Customer potential needs"},
]

export const STATS_POTENTIAL_UPSELLING: SentenceType[] = [
  { id: "aiIsPotentialUpselling", sentence_type: "Possibility to upsell"},
]

export const STATS_FOLLOW_UP_ACTIONS: SentenceType[] = [
  { id: "aiFollowUpActions", sentence_type: "Follow-up action"},
]

export const STATS_TOTAL_QUESTION: SentenceType[] = [
  { id: "aiTotalQuestion", sentence_type: "Total question"},
]

export const STATS_TOTAL_OBJECTION: SentenceType[] = [
  { id: "aiTotalObjection", sentence_type: "Total objection"},
]

export const STATS_BUYER_POSITIVITY: SentenceType[] = [
  { id: "aiAveragePositivity", sentence_type: "Buyer positivity"},
]

export const STATS_PROMISE: SentenceType[] = [
  { id: "aiIsPromise", sentence_type: "Promises"},
]

export const AGENT_DATA_EXTRACTOR_COMPONENT = 'aiAgent';
export const AGENT_PLAYBOOK_COMPONENT = 'playbook';

export const AGENT_PLAYBOOK_NAME = 'Agent (Playbook)';
export const AGENT_DATA_EXTRACTOR_NAME = 'Agent (Data Extractor)';

export const abbreviations = [
  ...STATS_QUESTIONS,
  ...STATS_OBJECTIONS,
  ...STATS_STATEMENTS,
  ...STATS_SENTIMENTS,
  ...STATS_PRODUCT_ISSUES,
  ...STATS_PAIN_POINTS,
  ...STATS_NEW_NEEDS,
  ...STATS_POTENTIAL_UPSELLING,
  ...STATS_FOLLOW_UP_ACTIONS,
  ...STATS_TOTAL_QUESTION,
  ...STATS_TOTAL_OBJECTION,
  ...STATS_BUYER_POSITIVITY,
  ...STATS_PROMISE,
];

export const MODAL_CHARTS = {
  PERIOD: 'period',
  COMPARE: 'compare',
  TOTAL: 'total',
};

export const PERIOD_TYPES = {
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
};

export const GROUP_VALUE = 'value';
export const GROUP_USER = 'user';
export const GROUP_TEAM = 'team';
export const GROUP_NONE = 'none';
export const DENOMINATOR_INTERACTION = 'interaction';
export const DENOMINATOR_HOURLY = 'hourly';
export const DENOMINATOR_DEAL_TO_DEAL_INTERACTIONS = 'deal_to_deal_interactions';
export const DENOMINATOR_DEAL_TO_TOTAL_INTERACTIONS = 'deal_to_total_interactions';
export const GROUP_EMAIL = 'email';
export const GROUP_DOMAIN = 'domain';
export const TOTAL = 'total';

export const COMPARE_BY_VALUE_KEY = 'tag_value';
export const COMPARE_BY_USER_KEY = 'user_id';
export const COMPARE_BY_TEAM_KEY = 'team_id';
export const COMPARE_BY_SPEAKER_EMAIL_KEY = 'speaker_email';
export const COMPARE_BY_SPEAKER_DOMAIN_KEY = 'speaker_domain';

export const isUnhandledWidgetType = (widgetType: string): boolean => {
    return widgetType === STATISTIC_WIDGET_TYPES.KEYWORD
    || widgetType === STATISTIC_WIDGET_TYPES.SELLER_QUESTION
    || widgetType === STATISTIC_WIDGET_TYPES.BUYER_OBJECTION
    || widgetType === STATISTIC_WIDGET_TYPES.BUYER_QUESTION
    || widgetType === STATISTIC_WIDGET_TYPES.IMPORTANCE
    || widgetType === STATISTIC_WIDGET_TYPES.CUSTOMER_SENTIMENT
    || widgetType === STATISTIC_WIDGET_TYPES.ASYNC_PROMISE
    || widgetType === STATISTIC_WIDGET_TYPES.ASYNC_UNPROFESSIONAL_BEHAVIOUR
    || widgetType === STATISTIC_WIDGET_TYPES.AI_AGENT
}

export const isBinaryWidgetType = (widgetType: string): boolean => {
  return widgetType === STATISTIC_WIDGET_TYPES.POTENTIAL_UPSELLING
    || widgetType === STATISTIC_WIDGET_TYPES.FOLLOW_UP_ACTIONS
    || widgetType === STATISTIC_WIDGET_TYPES.PAIN_POINTS
    || widgetType === STATISTIC_WIDGET_TYPES.PRODUCT_ISSUES
    || widgetType === STATISTIC_WIDGET_TYPES.NEW_NEEDS
    || widgetType === STATISTIC_WIDGET_TYPES.TOTAL_QUESTION
    || widgetType === STATISTIC_WIDGET_TYPES.BUYER_POSITIVITY
    || widgetType === STATISTIC_WIDGET_TYPES.TOTAL_OBJECTION
}

export const isSpeakerSpecificWidget = (widgetType: string) => {
  return (
    widgetType === STATISTIC_WIDGET_TYPES.BUYER_OBJECTION ||
    widgetType === STATISTIC_WIDGET_TYPES.BUYER_QUESTION ||
    widgetType === STATISTIC_WIDGET_TYPES.SELLER_QUESTION ||
    widgetType === STATISTIC_WIDGET_TYPES.PRODUCT_ISSUES ||
    widgetType === STATISTIC_WIDGET_TYPES.PAIN_POINTS ||
    widgetType === STATISTIC_WIDGET_TYPES.NEW_NEEDS ||
    widgetType === STATISTIC_WIDGET_TYPES.CUSTOMER_SENTIMENT ||
    widgetType === STATISTIC_WIDGET_TYPES.BUYER_POSITIVITY ||
    widgetType === STATISTIC_WIDGET_TYPES.ASYNC_PROMISE ||
    widgetType === STATISTIC_WIDGET_TYPES.ASYNC_UNPROFESSIONAL_BEHAVIOUR
  );
};

export const isAnalyticStatsWidget = (widgetType: string) => {
  return (
    widgetType === ANALYTICS_STATS_WIDGET_TYPES.QUESTIONS ||
    widgetType === ANALYTICS_STATS_WIDGET_TYPES.SLIDES_SHOWN ||
    widgetType === ANALYTICS_STATS_WIDGET_TYPES.AVG_WORD_PER_MINUTE ||
    widgetType === ANALYTICS_STATS_WIDGET_TYPES.DIRECT_SHARE_PERCENTAGE ||
    widgetType === ANALYTICS_STATS_WIDGET_TYPES.FACE_2_FACE_PERCENTAGE ||
    widgetType === ANALYTICS_STATS_WIDGET_TYPES.MEETING_SCORE ||
    widgetType === ANALYTICS_STATS_WIDGET_TYPES.MEETING_ENGAGEMENT_PERCENTAGE ||
    widgetType === ANALYTICS_STATS_WIDGET_TYPES.SCREEN_SHARE_PERCENTAGE ||
    widgetType === ANALYTICS_STATS_WIDGET_TYPES.DEALS ||
    widgetType === ANALYTICS_STATS_WIDGET_TYPES.AVG_DIVISION ||
    widgetType === ANALYTICS_STATS_WIDGET_TYPES.AVG_INTERRUPTIONS ||
    widgetType === ANALYTICS_STATS_WIDGET_TYPES.AVG_MEETING_TIME ||
    widgetType === ANALYTICS_STATS_WIDGET_TYPES.AVG_SHIFTS_PER_MINUTE ||
    widgetType === ANALYTICS_STATS_WIDGET_TYPES.LONGEST_MONOLOGUE ||
    widgetType === ANALYTICS_STATS_WIDGET_TYPES.LONGEST_USER_STORY ||
    widgetType === ANALYTICS_STATS_WIDGET_TYPES.MEETINGS ||
    widgetType === ANALYTICS_STATS_WIDGET_TYPES.SCHEDULED_INTERACTIONS ||
    widgetType === ANALYTICS_STATS_WIDGET_TYPES.TOTAL_MEETING_TIME ||
    widgetType === ANALYTICS_STATS_WIDGET_TYPES.CALL_OUTCOMES
  );
};

export const isStatisticWidgetType = (widgetType: string) => {
  return (
    widgetType === STATISTIC_WIDGET_TYPES.KEYWORD ||
    widgetType === STATISTIC_WIDGET_TYPES.SENTIMENT ||
    widgetType === STATISTIC_WIDGET_TYPES.PRODUCT_ISSUES ||
    widgetType === STATISTIC_WIDGET_TYPES.PAIN_POINTS ||
    widgetType === STATISTIC_WIDGET_TYPES.NEW_NEEDS ||
    widgetType === STATISTIC_WIDGET_TYPES.POTENTIAL_UPSELLING ||
    widgetType === STATISTIC_WIDGET_TYPES.FOLLOW_UP_ACTIONS ||
    widgetType === STATISTIC_WIDGET_TYPES.BUYER_QUESTION ||
    widgetType === STATISTIC_WIDGET_TYPES.BUYER_OBJECTION ||
    widgetType === STATISTIC_WIDGET_TYPES.SELLER_QUESTION ||
    widgetType === STATISTIC_WIDGET_TYPES.IMPORTANCE ||
    widgetType === STATISTIC_WIDGET_TYPES.CUSTOMER_SENTIMENT ||
    widgetType === STATISTIC_WIDGET_TYPES.TOTAL_QUESTION ||
    widgetType === STATISTIC_WIDGET_TYPES.TOTAL_OBJECTION ||
    widgetType === STATISTIC_WIDGET_TYPES.BUYER_POSITIVITY ||
    widgetType === STATISTIC_WIDGET_TYPES.ASYNC_PROMISE ||
    widgetType === STATISTIC_WIDGET_TYPES.ASYNC_UNPROFESSIONAL_BEHAVIOUR ||
    widgetType.includes(STATISTIC_WIDGET_TYPES.AI_AGENT) ||
    widgetType.includes('aiAgent')
  );
};

export const isUnclickableWidget = (widgetType: string, filters: any) => {
  return (
      widgetType === STATISTIC_WIDGET_TYPES.BUYER_POSITIVITY ||
      widgetType === STATISTIC_WIDGET_TYPES.IMPORTANCE
  );
}

export const isWidgetWithoutAbbreviation = (widgetType: string) => {
  return (
      widgetType === STATISTIC_WIDGET_TYPES.KEYWORD ||
      widgetType === STATISTIC_WIDGET_TYPES.IMPORTANCE
  );
}

export const isWidgetWithSummary = (widgetType: string) => {
  return (
    widgetType === STATISTIC_WIDGET_TYPES.ASYNC_UNPROFESSIONAL_BEHAVIOUR ||
    widgetType === STATISTIC_WIDGET_TYPES.ASYNC_PROMISE ||
    widgetType === STATISTIC_WIDGET_TYPES.AI_AGENT
  );
}

export const BINARY_WIDGET_NAMES = {
  [STATISTIC_WIDGET_TYPES.POTENTIAL_UPSELLING]: 'Upsell possibility',
  [STATISTIC_WIDGET_TYPES.FOLLOW_UP_ACTIONS]: 'Follow-up action',
  [STATISTIC_WIDGET_TYPES.PAIN_POINTS]: 'Customer concern',
  [STATISTIC_WIDGET_TYPES.NEW_NEEDS]: 'Customer needs',
  [STATISTIC_WIDGET_TYPES.PRODUCT_ISSUES]: 'Customer product issue',
  [STATISTIC_WIDGET_TYPES.TOTAL_QUESTION]: 'Total question',
  [STATISTIC_WIDGET_TYPES.TOTAL_OBJECTION]: 'Total objection',
  [STATISTIC_WIDGET_TYPES.BUYER_POSITIVITY]: 'Buyer positivity',
}

export const UNHANDLED_WIDGET_NAMES = {
  [STATISTIC_WIDGET_TYPES.KEYWORD]: 'Keyword',
  [STATISTIC_WIDGET_TYPES.BUYER_QUESTION]: 'Buyer question',
  [STATISTIC_WIDGET_TYPES.BUYER_OBJECTION]: 'Buyer objection',
  [STATISTIC_WIDGET_TYPES.SELLER_QUESTION]: 'Seller question',
  [STATISTIC_WIDGET_TYPES.CUSTOMER_SENTIMENT]: 'Buyer emotions',
  [STATISTIC_WIDGET_TYPES.IMPORTANCE]: 'Importance',
  [STATISTIC_WIDGET_TYPES.ASYNC_PROMISE]: 'Promise',
  [STATISTIC_WIDGET_TYPES.ASYNC_UNPROFESSIONAL_BEHAVIOUR]: 'Unprofessional behaviour',
  [STATISTIC_WIDGET_TYPES.AI_AGENT]: 'Agent (Data Extractor)',
}

export const ANALYTICS_STAT_WIDGET_NAMES = {
  [ANALYTICS_STATS_WIDGET_TYPES.QUESTIONS]: questions,
  [ANALYTICS_STATS_WIDGET_TYPES.SLIDES_SHOWN]: slidesShown,
  [ANALYTICS_STATS_WIDGET_TYPES.AVG_WORD_PER_MINUTE]: wordsPerMinute,
  [ANALYTICS_STATS_WIDGET_TYPES.DIRECT_SHARE_PERCENTAGE]: directShare,
  [ANALYTICS_STATS_WIDGET_TYPES.FACE_2_FACE_PERCENTAGE]: faceToFace,
  [ANALYTICS_STATS_WIDGET_TYPES.MEETING_SCORE]: meetingScore,
  [ANALYTICS_STATS_WIDGET_TYPES.MEETING_ENGAGEMENT_PERCENTAGE]: customerInTab,
  [ANALYTICS_STATS_WIDGET_TYPES.SCREEN_SHARE_PERCENTAGE]: screenShare,
  [ANALYTICS_STATS_WIDGET_TYPES.DEALS]: deals,
  [ANALYTICS_STATS_WIDGET_TYPES.AVG_DIVISION]: talkListenRatio,
  [ANALYTICS_STATS_WIDGET_TYPES.AVG_INTERRUPTIONS]: averageInterruptions,
  [ANALYTICS_STATS_WIDGET_TYPES.AVG_MEETING_TIME]: avgInteractionTime,
  [ANALYTICS_STATS_WIDGET_TYPES.AVG_SHIFTS_PER_MINUTE]: conversationShifts,
  [ANALYTICS_STATS_WIDGET_TYPES.LONGEST_MONOLOGUE]: longestMonologues,
  [ANALYTICS_STATS_WIDGET_TYPES.LONGEST_USER_STORY]: customerStories,
  [ANALYTICS_STATS_WIDGET_TYPES.MEETINGS]: numberOfInteractions,
  [ANALYTICS_STATS_WIDGET_TYPES.SCHEDULED_INTERACTIONS]: scheduledInteractions,
  [ANALYTICS_STATS_WIDGET_TYPES.TOTAL_MEETING_TIME]: interactionTime,
  [ANALYTICS_STATS_WIDGET_TYPES.CALL_OUTCOMES]: callOutcomes,
};

export interface SentenceType {
  id: string;
  sentence_type: string;
}

export interface DataTag {
  statType: string;
  value: number;
}

export interface IUserIdWithName {
  id: number;
  name: string;
}

export interface ITeamIdWihName {
  id: string;
  name: string;
}

export interface IGroupByMap {
  user: string;
  team: string;
  value: string;
  email: string;
  domain: string;
}

export interface IGlobalInterval {
  globalStart: string;
  globalEnd: string;
}

export interface IStatsRecordingData {
  count: number;
  first_sentence?: string;
  recording: IStatsRecording;
  is_mentioned: boolean;
  summaries?: SessionSummaryText[];
}

export interface INumberOfInteractionsStatsRecording {
  id?: string;
  name: string;
  createdAt?: string;
  screenshots: string[];
  sessionId?: string;
  ownerName: string;
}

export interface IStatsRecording {
  id: string;
  name: string;
  createdAt: string;
  screenshots: string[];
  sessionId: string;
  speakerName: string;
  ownerName: string;
  url: string;
}

// interface which is used for global filters which will be saved in database
export interface IGlobalFilters {
  recording_types?: string[];
  campaign_ids?: string[];
  deals_status?: string[];
  calls_outcome?: string[];
  specific_deal_statuses?: string[];
  global_team_ids?: string[];
  global_user_ids?: number[];
  session_id?: string;
  global_meeting_types?: string[];
  interval?: string[];
  global_start?: string;
  global_end?: string;
  global_period?: string;
  limit?: number;
  agent_ids?: number[];
}

export type FormatterFunction = {
  [key: string]: (value: number) => string;
  hourly: (value: number) => string;
  interaction: (value: number) => string;
  default: (value: number) => string;
};

export const formatters: FormatterFunction = {
  [DENOMINATOR_HOURLY]: (value: number) => `${value} per hour`,
  [DENOMINATOR_INTERACTION]: (value: number) => `${value}%`,
  [DENOMINATOR_DEAL_TO_DEAL_INTERACTIONS]: (value: number) => `${value}%`,
  [DENOMINATOR_DEAL_TO_TOTAL_INTERACTIONS]: (value: number) => `${value}%`,
  default: (value: number) => value?.toString(),
};

export type WidgetInfo = {
  name: string;
  label: string;
  description: string;
  widgetId: string;
  agentId?: number;
}

export const defaultFilters = {
  groupBy: GROUP_USER,
  speakerType: "",
  period: "",
  chartType: MODAL_CHARTS.COMPARE,
  denominator: "",
  specificCallOutcomes: [],
  variableIds: [],
};

export const CALL_OUTCOMES = "Call outcomes";
export const DEALS = "Deals";
export const NOT_AN_OBJECTION = 'nao';

export type SessionSummaryText = {
  id: string,
  summary: string,
}

export function getChartNameByType(chartType: string) {
  if (chartType === MODAL_CHARTS.TOTAL) {
    return 'Total';
  } else if (chartType === MODAL_CHARTS.COMPARE) {
    return 'Compare';
  } else {
    return 'Period';
  }
}

export const getCompareValues = (groupBy: string, value: string, teams: IAssociatedTeam[], users: IAssociatedUser[]) => {
  const id = groupBy === GROUP_USER
  ? users.find(user => `${user.first_name} ${user.last_name}` === value)
  : teams.find(team => team.name === value);

  return { key: groupBy, value: id?.id}
}

export const forceCorrectAnalyticWidgetFilters = ({ ...filters }: Filters): Filters => {
  if (filters.chartType === MODAL_CHARTS.TOTAL) {
    filters.groupBy = GROUP_VALUE;
  } else if (filters.chartType === MODAL_CHARTS.PERIOD
      && (filters.groupBy === GROUP_USER || filters.groupBy === GROUP_TEAM)) {
    filters.groupBy = TOTAL;
  } else if (filters.chartType === MODAL_CHARTS.COMPARE && filters.groupBy === GROUP_VALUE) {
    filters.groupBy = GROUP_USER;
  }
  return filters;
};

export const forceCorrectSentenceWidgetFilters = ({ ...filters }: Filters): Filters => {
  // if (filters.chartType === 'compare' && filters.groupBy === GROUP_VALUE) {
  //   filters.groupBy = GROUP_USER;
  // }
  return filters;
};

export const forceAppropriateFilters = (widgetType: string, filters: Filters, areDifferentWidgets?: boolean): Filters => {
  let forcedFilters = {...filters};

  if (areDifferentWidgets && ![GROUP_USER, GROUP_TEAM].includes(filters.groupBy)) {
    forcedFilters.groupBy = GROUP_USER;
  } else if (filters.chartType === MODAL_CHARTS.PERIOD) {
    forcedFilters.groupBy = TOTAL;
  } else if (filters.chartType !== MODAL_CHARTS.TOTAL && filters.groupBy === TOTAL) {
    forcedFilters.groupBy = GROUP_VALUE;
  }

  if (!isSpeakerSpecificWidget(widgetType)) {
    forcedFilters.speakerType = '';
  }

  if (widgetType !== STATISTIC_WIDGET_TYPES.KEYWORD) {
    forcedFilters.specificStats = [];
    forcedFilters.keywordGroupIds = [];
  }

  if (widgetType === STATISTIC_WIDGET_TYPES.BUYER_POSITIVITY) {
    forcedFilters.denominator = '';
  }

  if (widgetType !== ANALYTICS_STATS_WIDGET_TYPES.CALL_OUTCOMES) {
    forcedFilters.specificCallOutcomes = [];
  }

  if (widgetType !== ANALYTICS_STATS_WIDGET_TYPES.DEALS) {
    forcedFilters.specificDealStatuses = [];
  }

  return forcedFilters;
}

export const forceCorrectAnalyticGroupByFilter = (
  options: IWidgetOptions,
  widgetType: string
): IWidgetOptions => {
  let updatedOptions: IWidgetOptions = { ...options };

  if (
    isAnalyticStatsWidget(widgetType) &&
    !isAnalyticWidgetWithValueOption(widgetType) &&
    updatedOptions.filters.chartType === MODAL_CHARTS.COMPARE &&
    updatedOptions.filters.groupBy === GROUP_VALUE
  ) {
    updatedOptions.filters.groupBy = GROUP_USER;
  }

  return updatedOptions;
};

export const isAnalyticWidgetWithValueOption = (widgetType: string) => {
  return widgetType === 'deals' || widgetType === 'call_outcomes';
}

export const VARIABLE_OUTPUT_OPTIONS: DropdownOption[] = [
  { id: 1, label: 'True', value: 'true' },
  { id: 2, label: 'False', value: 'false' },
];
