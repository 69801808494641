export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const CHECK_GOOGLE_LOGIN_USER = 'CHECK_GOOGLE_LOGIN_USER';
export const GOOGLE_DRIVE_LOGIN = 'GOOGLE_DRIVE_LOGIN';
export const CHECK_GOOGLE_DRIVE_LOGIN_USER = 'CHECK_GOOGLE_DRIVE_LOGIN_USER';
export const LOGIN_AS_USER = 'LOGIN_AS_USER';
export const LOGIN_BY_SESSION = 'LOGIN_BY_SESSION';
export const LOGIN_AS_USER_SUCCESS = 'LOGIN_AS_USER_SUCCESS';
export const GET_ALL_USERS_LIST = 'GET_ALL_USERS_LIST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const API_ERROR = 'LOGIN_API_ERROR';
export const LOGIN_SHARE_LINK = 'LOGIN_SHARE_LINK';
export const LOGIN_SHARE_LINK_SUCCESS = 'LOGIN_SHARE_LINK_SUCCESS';
export const SWITCH_TO_SESSION = "SWITCH_TO_SESSION";
export const SWITCH_TO_SESSION_ERROR = "SWITCH_TO_SESSION_ERROR";
export const CHECK_ZOHO_LOGIN_USER = "CHECK_ZOHO_LOGIN_USER";
export const SALESFORCE_USER_AUTH_SUCCESS = "SALESFORCE_USER_AUTH_SUCCESS";
export const CHECK_DYNAMICS_LOGIN_USER = "CHECK_DYNAMICS_LOGIN_USER";
export const CHECK_PIPEDRIVE_LOGIN_USER = "CHECK_PIPEDRIVE_LOGIN_USER";
export const CHECK_HUBSPOT_LOGIN_USER = "CHECK_HUBSPOT_LOGIN_USER";
export const CHECK_GOOGLE_MEET_LOGIN_USER = "CHECK_GOOGLE_MEET_LOGIN_USER";
export const CHECK_MICROSOFT_TEAMS_LOGIN_USER = "CHECK_MICROSOFT_TEAMS_LOGIN_USER";
