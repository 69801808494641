import React from "react";
import { Container, Row, Col } from "reactstrap";
import { isMeetricOrigin } from '../../services/partner/partnerService';

const Footer = () => {
  const isMeetric = isMeetricOrigin();

  return (
    <footer className="footer">
      <Container fluid={true}>
        <Row>
          <Col md={12} className="text-center">
            {new Date().getFullYear()} © {isMeetric ? 'Meetric .' : ''}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
