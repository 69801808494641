import React, { Component } from 'react';

import { getGPTPromptById } from '../../services/GPTPrompt/GPTPromptService';
import GptPromptItemComponent from '../../components/GPTPrompt/GPTPromptItem';
import { renderLoadingIndicator } from '../../components/Widgets/utils/helperFunction';

interface GptPromptTabProps {
  isLoading: boolean;
  promptId: string;
  onTableFormatChange: (isTableFormat: boolean) => void;
  onDataLengthChange: (dataLength: number) => void;
  onLoaded: () => void;
}

interface GptPromptState {
  isLoading: boolean;
  error: null | Error;
  promptData: string | null;
}

class GptPromptTab extends Component<GptPromptTabProps, GptPromptState> {
  intervalId: NodeJS.Timeout | null = null;

  constructor(props: GptPromptTabProps) {
    super(props);
    this.state = {
      isLoading: props.isLoading,
      error: null,
      promptData: null,
    };
  }

  componentDidMount() {
    this.fetchGptPromptById();
    this.intervalId = setInterval(this.fetchGptPromptById, 5000);
  }

  componentWillUnmount() {
    if (this.intervalId) clearInterval(this.intervalId);
  }

  onLoadingDone = () => {
    this.setState({ isLoading: false });
    this.props.onLoaded();
  }

  fetchGptPromptById = async () => {
    const { promptId } = this.props;
    const { promptData } = this.state;

    if (promptData === null) {
      try {
        this.setState({ isLoading: true });
        const promptData = await getGPTPromptById(promptId);

        if (promptData !== null) {
          this.onLoadingDone();
          this.setState({ promptData, error: null });

          if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
          }
        }
      } catch (error: any) {
        console.error('Error fetching GPT prompt by ID', error);
        this.onLoadingDone();
        this.setState({ error });

        if (this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      }
    }
  };

  render() {
    const { promptData, isLoading, error } = this.state;
    const { onDataLengthChange, onTableFormatChange } = this.props;

    if (isLoading) return <div>{renderLoadingIndicator()}</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
      <GptPromptItemComponent
        promptData={promptData}
        onTableFormatChange={onTableFormatChange}
        onDataLengthChange={onDataLengthChange}
      />
    );
  }
}

export default GptPromptTab;
