import { defaultAIAgent, } from '../../pages/AIAgent/utils/constants';
import {
  CREATE_AI_AGENT,
  CREATE_AI_AGENT_ERROR,
  CREATE_AI_AGENT_SUCCESS,
  DELETE_AI_AGENT,
  DELETE_AI_AGENT_ERROR,
  DELETE_AI_AGENT_SUCCESS,
  EMPTY_AGENT,
  EMPTY_TRANSCRIPT,
  GET_AI_AGENT,
  GET_AI_AGENT_ERROR,
  GET_AI_AGENT_REQUEST_ERROR,
  GET_AI_AGENT_REQUEST_SUCCESS,
  GET_AI_AGENT_REQUESTS,
  GET_AI_AGENT_REQUESTS_ERROR,
  GET_AI_AGENT_REQUESTS_SUCCESS,
  GET_AI_AGENT_SUCCESS,
  GET_AI_AGENT_TYPES,
  GET_AI_AGENT_TYPES_ERROR,
  GET_AI_AGENT_TYPES_SUCCESS,
  GET_AI_AGENTS,
  GET_AI_AGENTS_ERROR,
  GET_AI_AGENTS_SUCCESS,
  GET_PLAYBOOK_FOR_SESSION,
  GET_PLAYBOOK_FOR_SESSION_SUCCESS,
  GET_PLAYBOOK_FOR_SESSION_ERROR,
  GET_ALL_PLAYBOOKS_FOR_SESSION,
  GET_ALL_PLAYBOOKS_FOR_SESSION_SUCCESS,
  GET_ALL_PLAYBOOKS_FOR_SESSION_ERROR,
  GET_ALL_DATA_INSIGHTS_FOR_SESSION,
  GET_ALL_DATA_INSIGHTS_FOR_SESSION_SUCCESS,
  GET_ALL_DATA_INSIGHTS_FOR_SESSION_ERROR,
  SET_CURRENT_AI_AGENT,
  SET_TRANSCRIPT,
  TRY_AI_AGENT_REQUEST,
  TRY_AI_AGENT_REQUEST_ERROR,
  TRY_AI_AGENT_REQUEST_SUCCESS,
  UPDATE_AI_AGENT,
  UPDATE_AI_AGENT_ERROR,
  UPDATE_AI_AGENT_SUCCESS,
} from './actionTypes';

import {
  AIAgentAction,
  AIAgentState,
  IAIAgent,
  IAIAgentFromBackend,
  IAIAgentRequest,
  IAIAgentRequestFromBackend,
  IAIType,
  IAITypeFromBackend,
} from './interface';
import { createAgentFromAPIResponse, createAgentRequestFromAPIResponse } from './actions';

const initialState: AIAgentState = {
  aiAgents: [],
  isLoading: false,
  error: '',

  isSelectedAIAgentLoading: false,
  selectedAIAgent: defaultAIAgent,
  selectedAIAgentError: '',

  isCreateLoading: false,
  createError: '',

  isUpdateLoading: false,
  updateError: '',

  isDeleteLoading: false,
  deleteError: '',

  aiAgentRequests: null,
  aiAgentRequestsLoading: false,
  aiAgentRequestsError: '',

  selectedAIAgentRequest: null,
  selectedAIAgentRequestStatus: '',
  selectedAIAgentRequestError: '',

  allAIAgentTypes: [],
  isLoadingAllAIAgentTypes: false,
  allAIAgentTypesError: '',

  transcriptPreview: null,

  playbookForSession: null,
  isLoadingPlaybookForSession: false,

  allPlaybooksForSession: null,
  isLoadingAllPlaybooksForSession: false,

  allDataInsightsForSession: null,
  isLoadingAllDataInsightsForSession: false,
};

const AIAgent = (state = initialState, action: AIAgentAction) => {
  switch (action.type) {
    case GET_AI_AGENTS:
      state = {
        ...state,
        aiAgents: [],
        isLoading: true,
      };
      break;
    case GET_AI_AGENTS_SUCCESS:
      const transformedAgents: IAIAgent[] = action.payload.map(
        (agent: IAIAgentFromBackend) => ({
          id: agent.id,
          name: agent.name,
          type: agent.type,
          typeSettings: agent.type_settings,
          customPrompt: agent.custom_prompt,
          globalFilters: agent.global_filters,
          status: agent.status,
          createdAt: agent.created_at,
        })
      );

      return {
        ...state,
        aiAgents: transformedAgents,
        aiAgentRequests: null,
        selectedAIAgentRequest: null,
        isLoading: false,
      };
    case GET_AI_AGENTS_ERROR:
      state = {
        ...state,
        error: action.payload,
        isLoading: false,
      };
      break;
    case CREATE_AI_AGENT:
      state = {
        ...state,
        isCreateLoading: true,
      };
      break;
    case CREATE_AI_AGENT_SUCCESS:
      state = {
        ...state,
        aiAgents: [action.payload, ...state.aiAgents],
        isCreateLoading: false,
        selectedAIAgent: action.payload,
      };
      break;
    case CREATE_AI_AGENT_ERROR:
      state = {
        ...state,
        isCreateLoading: false,
        createError: action.payload,
      };
      break;
    case DELETE_AI_AGENT:
      state = {
        ...state,
        isDeleteLoading: true,
      };
      break;
    case DELETE_AI_AGENT_SUCCESS:
      state = {
        ...state,
        isDeleteLoading: false,
      };
      break;
    case DELETE_AI_AGENT_ERROR:
      state = {
        ...state,
        deleteError: action.payload,
        isDeleteLoading: false,
      };
      break;
    case UPDATE_AI_AGENT:
      state = {
        ...state,
        isUpdateLoading: true,
      };
      break;
    case UPDATE_AI_AGENT_SUCCESS:
      state = {
        ...state,
        aiAgents: state.aiAgents.map((agent) =>
          agent.id === action.payload.id ? createAgentFromAPIResponse(action.payload) : agent
        ),
        isUpdateLoading: false,
      };
      break;
    case UPDATE_AI_AGENT_ERROR:
      state = {
        ...state,
        updateError: action.payload,
        isUpdateLoading: false,
      };
      break;
    case GET_AI_AGENT_REQUESTS:
      state = {
        ...state,
        aiAgentRequestsLoading: true,
      };
      break;
    case GET_AI_AGENT_REQUESTS_SUCCESS:
      state = {
        ...state,
        aiAgentRequestsLoading: false,
        aiAgentRequests: action.payload.map(
          (agentRequest: IAIAgentRequestFromBackend) =>
            createAgentRequestFromAPIResponse(agentRequest)
        ),
      };

      break;
    case GET_AI_AGENT_REQUESTS_ERROR:
      state = {
        ...state,
        aiAgentRequestsLoading: false,
        aiAgentRequestsError: action.payload,
      };
      break;
    case SET_CURRENT_AI_AGENT:
      const updatedAIAgentObject: IAIAgent = {
        id: action.payload.id,
        name: action.payload.name,
        type: action.payload.type,
        status: action.payload.status,
        customPrompt: action.payload.custom_prompt,
        typeSettings: action.payload.type_settings,
        globalFilters: action.payload.global_filters,
        createdAt: action.payload.created_at,
      };

      state = {
        ...state,
        selectedAIAgent: updatedAIAgentObject,
      };
      break;
    case TRY_AI_AGENT_REQUEST:
      state = {
        ...state,
        selectedAIAgentRequest: null,
        selectedAIAgentRequestStatus: 'queued',
      }
      break;
    case TRY_AI_AGENT_REQUEST_SUCCESS:
      const newAiAgentRequest = createAgentRequestFromAPIResponse(
        action.payload
      );

      const existingRequestIndex = state.aiAgentRequests?.findIndex(
        (request) => request.id === newAiAgentRequest.id
      );

      let updatedAiAgentRequests: IAIAgentRequest[] = [];

      if (existingRequestIndex === -1 || existingRequestIndex === undefined) {
        updatedAiAgentRequests = [
          newAiAgentRequest,
          ...(state.aiAgentRequests || []),
        ];
      } else {
        updatedAiAgentRequests = [...(state.aiAgentRequests || [])];
        updatedAiAgentRequests[existingRequestIndex] = newAiAgentRequest;
      }

      state = {
        ...state,
        selectedAIAgentRequest: newAiAgentRequest,
        selectedAIAgentRequestStatus: newAiAgentRequest.status,
        aiAgentRequests: updatedAiAgentRequests,
      };
      break;
    case TRY_AI_AGENT_REQUEST_ERROR:
      state = {
        ...state,
        selectedAIAgentRequest: null,
        selectedAIAgentRequestStatus: 'error',
        selectedAIAgentRequestError: action.payload,
      };
      break;
    case GET_AI_AGENT_REQUEST_SUCCESS:
      const request = createAgentRequestFromAPIResponse(action.payload);

      const requestIndex = state.aiAgentRequests?.findIndex(
        (request) => request.id === action.payload.id
      );

      let updatedRequests = [...(state.aiAgentRequests || [])];
      updatedRequests[requestIndex!] = request;

      state = {
        ...state,
        selectedAIAgentRequest: createAgentRequestFromAPIResponse(
          action.payload
        ),
        selectedAIAgentRequestStatus: action.payload.status,
        aiAgentRequests: updatedRequests,
      };
      break;
    case GET_AI_AGENT_REQUEST_ERROR:
      state = {
        ...state,
        selectedAIAgentRequestError: action.payload,
        selectedAIAgentRequestStatus: 'error',
      };
      break;
    case GET_AI_AGENT:
      state = {
        ...state,
        isSelectedAIAgentLoading: true,
      };
      break;
    case GET_AI_AGENT_SUCCESS:
      const mappedAIAgentObject: IAIAgent = {
        id: action.payload.id,
        name: action.payload.name || '',
        type: action.payload.type || '',
        status: action.payload.status,
        customPrompt: action.payload.custom_prompt || '',
        typeSettings: action.payload.type_settings || [],
        globalFilters: action.payload.global_filters || [],
        createdAt: action.payload.created_at || '',
      };

      state = {
        ...state,
        selectedAIAgent: mappedAIAgentObject,
        isSelectedAIAgentLoading: false,
      };
      break;
    case GET_AI_AGENT_ERROR:
      state = {
        ...state,
        selectedAIAgentError: action.payload,
        isSelectedAIAgentLoading: false,
      };
      break;
    case GET_AI_AGENT_TYPES:
      state = {
        ...state,
        isLoadingAllAIAgentTypes: true,
        allAIAgentTypes: [],
      };
      break;
    case GET_AI_AGENT_TYPES_SUCCESS:
      const transformedTypes: IAIType[] = action.payload.map(
        (type: IAITypeFromBackend) => ({
          id: type.id,
          name: type.name,
          type: type.type,
          defaultConfig: type.default_config,
          defaultPrompt: type.default_prompt,
          createdAt: type.created_at,
          samples: type.samples,
        })
      );

      state = {
        ...state,
        isLoadingAllAIAgentTypes: false,
        allAIAgentTypes: transformedTypes,
      };
      break;
    case GET_AI_AGENT_TYPES_ERROR:
      state = {
        ...state,
        isLoadingAllAIAgentTypes: false,
        allAIAgentTypesError: action.payload,
      };
      break;
    case GET_PLAYBOOK_FOR_SESSION:
      state = {
        ...state,
        isLoadingPlaybookForSession: true,
        playbookForSession: null,
      };
      break;
    case GET_PLAYBOOK_FOR_SESSION_SUCCESS:
      state = {
        ...state,
        isLoadingPlaybookForSession: false,
        playbookForSession: action.payload ? createAgentRequestFromAPIResponse(action.payload) : null,
      };
      break;
    case GET_PLAYBOOK_FOR_SESSION_ERROR:
      state = {
        ...state,
        isLoadingPlaybookForSession: false,
        playbookForSession: null,
      };
      break;
    case GET_ALL_PLAYBOOKS_FOR_SESSION:
      state = {
        ...state,
        isLoadingAllPlaybooksForSession: true,
        allPlaybooksForSession: null,
      };
      break;
    case GET_ALL_PLAYBOOKS_FOR_SESSION_SUCCESS:
      state = {
        ...state,
        isLoadingAllPlaybooksForSession: false,
        allPlaybooksForSession: action.payload
          ? action.payload.map((value) => createAgentRequestFromAPIResponse(value))
          : null,
      };
      break;
    case GET_ALL_PLAYBOOKS_FOR_SESSION_ERROR:
      state = {
        ...state,
        isLoadingAllPlaybooksForSession: false,
        allPlaybooksForSession: null,
      };
      break;
    case GET_ALL_DATA_INSIGHTS_FOR_SESSION:
      state = {
        ...state,
        isLoadingAllDataInsightsForSession: true,
        allDataInsightsForSession: null,
      };
      break;
    case GET_ALL_DATA_INSIGHTS_FOR_SESSION_SUCCESS:
      state = {
        ...state,
        isLoadingAllDataInsightsForSession: false,
        allDataInsightsForSession: action.payload
          ? action.payload.map((value) => createAgentRequestFromAPIResponse(value))
          : null,
      };
      break;
    case GET_ALL_DATA_INSIGHTS_FOR_SESSION_ERROR:
      state = {
        ...state,
        isLoadingAllDataInsightsForSession: false,
        allDataInsightsForSession: null,
      };
      break;
    case SET_TRANSCRIPT:
      state = {
        ...state,
        transcriptPreview: action.payload,
      };
      break;
    case EMPTY_TRANSCRIPT:
      state = {
        ...state,
        transcriptPreview: null,
        selectedAIAgentRequest: null,
      };
      break;
    case EMPTY_AGENT:
      state = {
        ...state,
        selectedAIAgent: defaultAIAgent,
        selectedAIAgentRequestStatus: ''
      }
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default AIAgent;
