import axios, { AxiosResponse } from 'axios';
import { ResponseGenerator } from '../../userservice';
import { urls } from '../../../utilities/urls';
import { commonheaders } from '../../../utilities/apiConfig';

export async function createTemporaryDynamicsToken(
  domain: string,
  isUpdateMeetingDescription: boolean,
) {
  try {
    const url = `${urls.baseApiURL}dynamics365/temporary-token?domain=${domain}&is_update_meeting_description=${isUpdateMeetingDescription ? 'true' : 'false'}`;

    const response: ResponseGenerator = await axios.post(
      url,
      commonheaders
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function updateDynamicsTokenFlag(
  tokenId: string,
  isUpdateMeetingDescription: boolean,
) {
  try {
    const url = `${urls.baseApiURL}dynamics365/${tokenId}/${isUpdateMeetingDescription ? 'enable' : 'disable'}`;

    const response: ResponseGenerator = await axios.post(
      url,
      commonheaders
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function updateSalesforceTokenFlag(
  tokenId: string,
  isUpdateMeetingDescription: boolean,
) {
  try {
    const url = `${urls.baseApiURL}salesforce/${tokenId}/${isUpdateMeetingDescription ? 'enable' : 'disable'}`;

    const response: ResponseGenerator = await axios.post(
      url,
      commonheaders
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}
