import React, { Component } from "react";
import { Input } from "reactstrap";
import { isEmpty } from "lodash";

import "./index.css";
import { validateEmail } from "../../services/utilities/utilservice";

interface InputTagsProps {
  tags: string[];
  tagType?: string;
  placeholder: string;
  loading?: boolean;
  updateTags: (tags: string[]) => void;
  customValidation?: (value: string) => boolean;
  disabled?: boolean;
}

interface InputTagsState {
  inputValue: string;
  isKeyReleased: boolean;
  error: string;
}

class InputTags extends Component<InputTagsProps, InputTagsState> {
  private inputField: React.RefObject<any>;

  constructor(props: InputTagsProps) {
    super(props);

    this.state = {
      inputValue: "",
      isKeyReleased: false,
      error: "",
    };

    this.inputField = React.createRef();
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({
      inputValue: value,
    });
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { inputValue, isKeyReleased } = this.state;
    const { tags, updateTags } = this.props;

    const { key, keyCode } = event;
    const trimmedInput = this.state.inputValue.trim();

    if ((key === ',' || keyCode === 13) && trimmedInput.length) {
      event.preventDefault();

      this.handleAddTag(trimmedInput);
    }

    // Delete
    if (keyCode === 8 && !inputValue.length && tags.length && isKeyReleased) {
      event.preventDefault();
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();

      updateTags(tagsCopy);
      this.setState({
        inputValue: poppedTag ?? "",
      });
    }

    if (keyCode === 13) {
      event.stopPropagation();
    }

    this.setState({ isKeyReleased: false });
  };

  deleteTag = (index: number, e: React.MouseEvent<HTMLButtonElement>) => {
    const { tags, updateTags } = this.props;

      e.preventDefault();
      const newArray = tags.filter(
      (tag, tagIndex) => tagIndex !== index
    );

    updateTags(newArray);
  };

  onKeyUp = () => {
    this.setState({ isKeyReleased: true });
  };

  onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { inputValue } = this.state;

    const trimmedInput = inputValue.trim();

    if (trimmedInput.length) {
      this.handleAddTag(trimmedInput);
    }
  };

  handleError = (errorMessage: string) => {
    this.setState({ error: errorMessage });

    setTimeout(() => {
      this.setState({ error: "" });
    }, 2000);
  };

  handleAddTag = (tagValue: string) => {
    const { tags, tagType, updateTags, customValidation } = this.props;

    if (tags.includes(tagValue)) {
      this.handleError("Value is already in the list!");

      return;
    }

    if (tagType === "email" && !validateEmail(tagValue)) {
      this.handleError("Please enter valid email address!");

      return;
    }

    if (customValidation && !customValidation(tagValue)) {
      this.handleError("Please enter valid domain!");

      return;
    }

    const updatedTags = [...tags, tagValue];

    updateTags(updatedTags);
    this.setState({
      inputValue: ""
    });
  };

  render() {
    const { inputValue, error } = this.state;
    const { tags, placeholder, loading, disabled } = this.props;

    return (
      <>
        <div className="tagContainer">
          {tags.map((tag, index) => (
            <div className="tag" key={index}>
              {tag}
              <button
                type="button"
                className="no-style buttonTextColorImportant"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                  this.deleteTag(index, e)
                }
                disabled={loading || disabled}
              >
                {disabled ? '' : 'x'}
              </button>
            </div>
          ))}
          <Input
            className="phrases-input"
            value={inputValue}
            placeholder={disabled ? '' : placeholder}
            onKeyDown={this.onKeyDown}
            onChange={this.onChange}
            onKeyUp={this.onKeyUp}
            onBlur={this.onBlur}
            ref={this.inputField}
            disabled={loading || disabled}
          />
        </div>
        {!isEmpty(error) && (
          <p className="mt-1" style={{ color: 'red' }}>
            {error}
          </p>
        )}
      </>
    );
  }
}

export default InputTags;
