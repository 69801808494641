import { History } from "history";
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOGIN_AS_USER,
  LOGIN_BY_SESSION,
  LOGIN_AS_USER_SUCCESS,
  GET_ALL_USERS_LIST,
  GET_ALL_USERS_SUCCESS,
  CHECK_GOOGLE_LOGIN_USER,
  GOOGLE_DRIVE_LOGIN,
  CHECK_GOOGLE_DRIVE_LOGIN_USER,
  LOGIN_SHARE_LINK,
  LOGIN_SHARE_LINK_SUCCESS,
  SWITCH_TO_SESSION,
  SWITCH_TO_SESSION_ERROR,
  CHECK_ZOHO_LOGIN_USER,
  SALESFORCE_USER_AUTH_SUCCESS,
  CHECK_DYNAMICS_LOGIN_USER,
  CHECK_PIPEDRIVE_LOGIN_USER,
  CHECK_HUBSPOT_LOGIN_USER,
  CHECK_GOOGLE_MEET_LOGIN_USER, CHECK_MICROSOFT_TEAMS_LOGIN_USER,
} from "./actionTypes";

export const loginUser = (
  user: Object,
  history: History,
  redirectURL: String,
  sessionId: null | String
) => {
  return {
    type: LOGIN_USER,
    payload: { user, history, redirectURL, sessionId },
  };
};

export const loginSuccess = (user: Object) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = (history: History) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const getAllUsers = () => {
  return {
    type: GET_ALL_USERS_LIST,
    payload: {},
  };
};

export const getUserSuccess = (users: Object[]) => {
  return {
    type: GET_ALL_USERS_SUCCESS,
    payload: users,
  };
};

export const loginAsUser = (userId: String, history: History) => {
  return {
    type: LOGIN_AS_USER,
    payload: { userId, history },
  };
};

export const loginBySession = (sessionId: String, history: History) => {
  return {
    type: LOGIN_BY_SESSION,
    payload: { sessionId, history },
  };
};

export const loginAsUserSuccess = (user: Object) => {
  return {
    type: LOGIN_AS_USER_SUCCESS,
    payload: user,
  };
};

export const loginGoogleUser = (code: string, history: History) => {
  return {
    type: CHECK_GOOGLE_LOGIN_USER,
    payload: { code, history },
  };
};

export const setGoogleDriveLogin = (callback: Function) => {
  return {
    type: GOOGLE_DRIVE_LOGIN,
    payload: { callback },
  };
};

export const loginGoogleDriveUser = (code: string, history: History) => {
  return {
    type: CHECK_GOOGLE_DRIVE_LOGIN_USER,
    payload: { code, history },
  };
};

export const loginZohoUser = (code: string, history: History) => {
  return {
    type: CHECK_ZOHO_LOGIN_USER,
    payload: { code, history },
  };
};

export const salesforceUserAuthSuccess = (code: string, history: History) => {
  return {
    type: SALESFORCE_USER_AUTH_SUCCESS,
    payload: { code, history },
  };
};

export const loginDynamicsUser = (code: string, history: History) => {
  return {
    type: CHECK_DYNAMICS_LOGIN_USER,
    payload: { code, history },
  };
};

export const loginPipedriveUser = (code: string, history: History) => {
  return {
    type: CHECK_PIPEDRIVE_LOGIN_USER,
    payload: { code, history },
  }
}

export const loginHubspotUser = (code: string, history: History) => {
  return {
    type: CHECK_HUBSPOT_LOGIN_USER,
    payload: { code, history },
  }
}

export const loginGoogleMeetUser = (code: string, history: History) => {
  return {
    type: CHECK_GOOGLE_MEET_LOGIN_USER,
    payload: { code, history },
  }
}

export const loginMicrosoftTeamsUser = (code: string, history: History) => {
  return {
    type: CHECK_MICROSOFT_TEAMS_LOGIN_USER,
    payload: { code, history },
  }
}

export const apiError = (error: Object | String) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const loginShareLink = (linkHash: string, onSuccess: any) => {
  return {
    type: LOGIN_SHARE_LINK,
    payload: { linkHash, onSuccess }
  };
}

export const loginShareLinkSuccess = (shareLinkJwt: Object) => {
  return {
    type: LOGIN_SHARE_LINK_SUCCESS,
    payload: shareLinkJwt,
  };
};

export const switchToSession = (link: string, history: History) => {
  return {
    type: SWITCH_TO_SESSION,
    payload: { link, history },
  }
}

export const switchToSessionError = (error: Object | string) => {
  return {
    type: SWITCH_TO_SESSION_ERROR,
    payload: error,
  }
}
