import {
  GET_VARIABLE,
  GET_VARIABLE_SUCCESS,
  GET_VARIABLE_ERROR,
  CREATE_VARIABLE,
  CREATE_VARIABLE_SUCCESS,
  CREATE_VARIABLE_ERROR,
  EDIT_VARIABLE,
  EDIT_VARIABLE_SUCCESS,
  EDIT_VARIABLE_ERROR,
  DELETE_VARIABLE,
  DELETE_VARIABLE_SUCCESS,
  DELETE_VARIABLE_ERROR,
} from './actionTypes';

import { IVariable } from './interface';

export const getVariables = () => ({
  type: GET_VARIABLE,
  payload: null,
});

export const getVariableSuccess = (variables: IVariable[]|null) => ({
  type: GET_VARIABLE_SUCCESS,
  payload: variables,
});

export const getVariableError = (error: String | Object) => ({
  type: GET_VARIABLE_ERROR,
  payload: error,
});

export const createVariable = (variable: IVariable, callback?: (variable: IVariable) => void) => ({
  type: CREATE_VARIABLE,
  payload: { variable, callback },
});

export const createVariableSuccess = (variable: IVariable) => ({
  type: CREATE_VARIABLE_SUCCESS,
  payload: variable,
});

export const createVariableError = (error: String | Object) => ({
  type: CREATE_VARIABLE_ERROR,
  payload: error,
});

export const editVariable = (variable: IVariable) => ({
  type: EDIT_VARIABLE,
  payload: variable,
});

export const editVariableSuccess = (variable: IVariable) => ({
  type: EDIT_VARIABLE_SUCCESS,
  payload: variable,
});

export const editVariableError = (error: String | Object) => ({
  type: EDIT_VARIABLE_ERROR,
  payload: error,
});

export const deleteVariable = (id: string) => ({
  type: DELETE_VARIABLE,
  payload: id,
});

export const deleteVariableSuccess = () => ({
  type: DELETE_VARIABLE_SUCCESS,
  payload: null,
});

export const deleteVariableError = (error: String | Object) => ({
  type: DELETE_VARIABLE_ERROR,
  payload: error,
});

