import { sleep } from "../../utilities/helperFunctions";

const FIVE_MB = 5 * 1024 * 1024;
const URLS_PAGE = 50;
export const firstPartFailedError = new Error('First part of recording failed to upload');

export async function streamToS3Urls(stream, urls, getMoreUrls) {
  let buffer = new ArrayBuffer(FIVE_MB);
  let dataArray = new Uint8Array(buffer);
  let byteOffset = 0;
  let currentUrlIndex = 0;
  const partPromises = [];

  let onRecordingEnded;
  const recordingEnded = new Promise(resolve => onRecordingEnded = resolve);
  const threeMinutesAfterRecordingEnded = recordingEnded.then(() => sleep(180000));

  let urlsAvailable = urls.length;

  // TODO: if uploading to undefined those are lost...
  const appendUrlsIfNeeded = async () => {
    if (urls.length - currentUrlIndex >= URLS_PAGE / 2) {
      return;
    }
    const urlsAvailableAtStart = urlsAvailable;
    const { data: newUrls } = await getMoreUrls(
      urlsAvailable + 1,
      Math.max(
        URLS_PAGE,
        URLS_PAGE + (currentUrlIndex - urlsAvailable) * 4
      ),
    );
    newUrls.forEach((url, i) => urls[urlsAvailableAtStart + i] = url);
    urlsAvailable = Math.max(urlsAvailable, urlsAvailableAtStart + newUrls.length);
  };

  const sendChunk = arrayToSend => {
    const partIndex = currentUrlIndex++;
    const fetchFunc = () => fetch(urls[partIndex], {
      method: 'PUT',
      headers: { 'Content-Type': 'multipart/form-data' },
      body: arrayToSend,
    });
    partPromises[partIndex] = partIndex > 0
      ? retry(fetchFunc)
      : Promise.race([
          retry(fetchFunc, { retryTimes: Infinity, maxWaitSeconds: 30 }),
          threeMinutesAfterRecordingEnded.then(() => Promise.reject(firstPartFailedError)),
        ]);
    return partPromises[partIndex];
  };

  return new Promise((resolve, reject) => {
    const writableStream = new WritableStream({
      write(chunk) {
        if (byteOffset + chunk.length <= FIVE_MB) {
          dataArray.set(chunk, byteOffset);
          byteOffset += chunk.length;
          return;
        }

        dataArray.set(chunk.slice(0, FIVE_MB - byteOffset), byteOffset);
        sendChunk(dataArray);

        buffer = new ArrayBuffer(FIVE_MB);
        dataArray = new Uint8Array(buffer);
        dataArray.set(chunk.slice(FIVE_MB - byteOffset), 0);
        byteOffset = byteOffset + chunk.length - FIVE_MB;

        retry(appendUrlsIfNeeded);
      },

      async close() {
        if (byteOffset > 0) {
          dataArray = dataArray.slice(0, byteOffset);
          sendChunk(dataArray);
        }
        try {
          onRecordingEnded();
          const responses = await Promise.all(partPromises);
          const etags = responses.map(response => response.headers.get('ETag').replace(/"/g, ''));
          resolve(etags);
        } catch (e) {
          reject(e);
        }
      },

      abort(reason) {
        reject(reason);
      },
    });

    stream.pipeTo(writableStream);
  });
};

export function detectWhenDisabled(stream, callback) {
  stream.getTracks().forEach(
    track => track.addEventListener('ended', () => {
      if (stream.getTracks().every(track => track.readyState === 'ended')) {
        callback(stream);
      }
    })
  );
}

export async function retry(asyncFunc, { retryTimes = 10, maxWaitSeconds = Infinity } = {}) {
  let attemptNum = 1;
  while (true) {
    try {
      return await asyncFunc();
    } catch (e) {
      if (++attemptNum > retryTimes) break;
      await sleep(Math.min(maxWaitSeconds, attemptNum * attemptNum) * 1000);
    }
  }
}
