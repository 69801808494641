import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { has } from 'lodash';

import {
  getVariableError,
  getVariableSuccess,
  createVariable as createVariableAction,
  createVariableError,
  createVariableSuccess,
} from "./actions";

import {
  getVariables,
  createVariable,
} from '../../services/Variable/variableService';

import { IVariable } from "./interface";
import {
  GET_VARIABLE,
  CREATE_VARIABLE,
} from "./actionTypes";
import { ResponseGenerator } from '../../services/userservice';


function* getVariableHelper() {
  const response: ResponseGenerator = yield call(getVariables);
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getVariableError(error));
    } else {
      yield put(getVariableError('An error has occurred!!'));
    }
  } else {
    yield put(getVariableSuccess(response as unknown as IVariable[]));
  }
}

function* createVariableHelper({ payload: { variable, callback }}: ReturnType<typeof createVariableAction>) {
  const response: ResponseGenerator = yield call(
    createVariable,
    variable,
  );

  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(createVariableError(error));
    } else {
      yield put(createVariableError('An error has occurred!!'));
    }
  } else {
    yield put(createVariableSuccess(response as unknown as IVariable));

    callback && callback(response as unknown as IVariable);
  }
}

// function* editVariableHelper({
//  payload,
// }: ReturnType<typeof editVariableAction>) {
//   const response: ResponseGenerator = yield call(
//     editVariable,
//     payload,
//   );
//   if (has(response, 'error') || has(response, 'errors')) {
//     const error = response.error || response.errors;
//     if (error) {
//       yield put(editVariableError(error));
//     } else {
//       yield put(editVariableError('An error has occurred!!'));
//     }
//   } else {
//     yield put(editVariableSuccess(response as unknown as IVariable));
//   }
// }
//
// function* deleteVariableHelper({
//   payload,
// }: ReturnType<typeof deleteVariableAction>) {
//   const response: ResponseGenerator = yield call(deleteVariable, payload);
//
//   if (has(response, 'error') || has(response, 'errors')) {
//     const error = response.error || response.errors;
//     if (error) {
//       yield put(deleteVariableError(error));
//     } else {
//       yield put(deleteVariableError('An error has occurred!!'));
//     }
//   } else {
//     yield put(deleteVariableSuccess());
//   }
// }

export function* watchGetVariableHelper() {
  yield takeEvery(GET_VARIABLE, getVariableHelper);
}

export function* watchCreateVariableHelper() {
  yield takeEvery(CREATE_VARIABLE, createVariableHelper);
}

// export function* watchEditVariableHelper() {
//   yield takeEvery(EDIT_VARIABLE, editVariableHelper);
// }
//
// export function* watchDeleteVariableHelper() {
//   yield takeEvery(DELETE_VARIABLE, deleteVariableHelper);
// }

function* VariableSaga() {
  yield all([fork(watchGetVariableHelper)]);
  yield all([fork(watchCreateVariableHelper)]);
  // yield all([fork(watchEditVariableHelper)]);
  // yield all([fork(watchDeleteVariableHelper)]);
}

export default VariableSaga;
