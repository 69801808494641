import { isEmpty } from 'lodash';

import { Filters } from '../../../store/layout/interface';
import {
  AGENT_PLAYBOOK_COMPONENT,
  CHART_TYPE_GROUPS,
  emptyGlobalFiltersObj,
  IGlobalFilters,
} from '../utils/constants';

import {
  formatMinutesToHours,
  formatSecondsToMinutes,
  roundValue,
  roundValueWithOneDecimal
} from '../../../services/utilities/newUtilservice';
import {
  averageInterruptions,
  avgInteractionTime,
  callOutcomes,
  conversationShifts,
  customerInTab,
  customerStories,
  deals,
  directShare,
  faceToFace,
  interactionTime,
  longestMonologues,
  meetingScore,
  numberOfInteractions,
  questions,
  scheduledInteractions,
  screenShare,
  slidesShown,
  talkListenRatio,
  wordsPerMinute
} from '../utils/WidgetNames';
import {
  ANALYTICS_STATS_WIDGET_TYPES,
  COMPARE_STATS_WIDGET_TYPE,
  STATISTIC_WIDGET_TYPES, VARIABLE_COMPONENT, VARIABLE_WIDGET_TYPES
} from '../../../pages/Home/utils/constants';
import { ICompareWidget } from '../utils/IWidget';
import { formatDecimal } from '../../../services/utilities/utilservice';
import { produce } from 'immer';

export interface IWidgetOptions {
  filters: Filters;
  globalFilters: IGlobalFilters;
  title?: string;
  compareFilters?: IWidgetSavingOptions[];
  color?: string;
  agentId?: number;
}

export interface IWidgetLayoutOptions {
  filters: Filters;
  globalFilters: IGlobalFilters;
  title?: string;
  agentId?: any;
  component?: string;
  color?: string;
  compareFilters?: this[];
}

export interface IWidgetCoordinates {
  x: number;
  y: number;
}

export interface IWidgetSavingOptions {
  widgetComponent: string;
  widgetTitle: string;
  filters: Filters;
  globalFilters: IGlobalFilters;
  color: string;
  compareFilters?: this[];
  agentId?: number;
}

export interface IWidgetCloningOptions {
  widgetComponent: string;
  widgetOptions: IWidgetOptions;
  widgetOriginalPosition: IWidgetCoordinates;
}

export interface IAnalyticWidgetDependencies {
  shownValueType: string;
  valueType: string;
  defaultTitle: string;
  widgetComponent: string;
  formatter: (value: number) => string;
}

export interface ISentenceWidgetDependencies {
  widgetComponent: string;
  widgetType: string;
  defaultTitle: string;
  formatter?: (value: any) => any;
}

export const ANALYTIC_WIDGET = 'analytic_widget';
export const SENTENCE_WIDGET = 'sentence_widget';
export const COMPARE_WIDGET = 'compare_widget';
export const AI_DATA_WIDGET = 'ai_data_widget';
export const VARIABLE_WIDGET = 'variable_widget';

export const WIDGET_DEFAULT_DATE_FORMAT = "DD MMM `YY";

export function determineWidgetTypeByComponent(
  widgetComponent: string
): typeof SENTENCE_WIDGET | typeof ANALYTIC_WIDGET | typeof COMPARE_WIDGET | typeof AI_DATA_WIDGET | typeof VARIABLE_WIDGET {
  if (
    widgetComponent === 'sentenceStatsKeyword' ||
    widgetComponent === 'sentenceStatsAsyncPromise' ||
    widgetComponent === 'sentenceStatsAsyncUnprofessionalBehaviour' ||
    widgetComponent === 'customerPositivity' ||
    widgetComponent === 'sentiment' ||
    widgetComponent === 'sentenceStatsProductIssues' ||
    widgetComponent === 'sentenceStatsPainPoints' ||
    widgetComponent === 'sentenceStatsNewNeeds' ||
    widgetComponent === 'sentenceStatsPotentialUpselling' ||
    widgetComponent === 'sentenceStatsFollowUpActions' ||
    widgetComponent === 'sentenceStatsBuyerObjection' ||
    widgetComponent === 'sentenceStatsBuyerQuestion' ||
    widgetComponent === 'sentenceStatsSellerQuestion' ||
    widgetComponent === 'sentenceStatsCustomerSentiment' ||
    widgetComponent === 'sentenceStatsImportance' ||
    widgetComponent === 'sentenceStatsTotalQuestion' ||
    widgetComponent === 'sentenceStatsTotalObjection' ||
    widgetComponent === 'sentenceStatsBuyerPositivity' ||
    widgetComponent === 'sentenceStatsPromise' ||
    widgetComponent.includes('aiAgent_')
  ) {
    return SENTENCE_WIDGET;
  }

  if (widgetComponent === COMPARE_STATS_WIDGET_TYPE) {
    return COMPARE_WIDGET;
  }

  if (isAgentWidget(widgetComponent)) {
    return AI_DATA_WIDGET;
  }

  if (widgetComponent === 'variable') {
    return VARIABLE_WIDGET;
  }

  return ANALYTIC_WIDGET;
}

export function isAgentWidget(widgetComponent: string): boolean {
  if (widgetComponent.includes(AGENT_PLAYBOOK_COMPONENT)) {
    return true;
  }

  return false;
}

export function getAIWidgetType(widgetComponent: string): string {
  if (widgetComponent === 'dataExtractor') {
    return "data_extractor";
  }

  if (widgetComponent === 'playbook') {
    return "playbook";
  }

  return widgetComponent
}

export function getAnalyticWidgetDependencies(widgetComponent: string): IAnalyticWidgetDependencies
{
  let dependencies: IAnalyticWidgetDependencies = {
    widgetComponent,
    shownValueType: '',
    valueType: '',
    formatter: roundValueWithOneDecimal,
    defaultTitle: '',
  }

  switch (widgetComponent) {
    case 'talkListenRatio':
      dependencies.shownValueType = 'avg_division';
      dependencies.valueType = ' % per meeting';
      dependencies.defaultTitle = talkListenRatio;

      break;
    case 'conversationShifts':
      dependencies.shownValueType = 'avg_shifts_per_minute';
      dependencies.valueType = ' / meeting';
      dependencies.defaultTitle = conversationShifts;

      break;
    case 'avgInterruptions':
      dependencies.shownValueType = 'avg_interruptions';
      dependencies.valueType = ' / meeting';
      dependencies.defaultTitle = averageInterruptions;

      break;
    case 'meetingScore':
      dependencies.shownValueType = 'meeting_score';
      dependencies.valueType = '%';
      dependencies.defaultTitle = meetingScore;

      break;
    case 'numberOfInteractions':
      dependencies.shownValueType = 'meetings';
      dependencies.valueType = '';
      dependencies.defaultTitle = numberOfInteractions;
      dependencies.formatter = roundValue;

      break;
    case 'scheduledInteractions':
      dependencies.shownValueType = 'scheduled_interactions';
      dependencies.valueType = '';
      dependencies.defaultTitle = scheduledInteractions;
      dependencies.formatter = roundValue;

      break;
    case 'questions':
      dependencies.shownValueType = 'avg_seller_questions_per_hour';
      dependencies.valueType = ' question / hour';
      dependencies.defaultTitle = questions;

      break;
    case 'longestMonologue':
      dependencies.shownValueType = 'longest_monologue';
      dependencies.valueType = ' min';
      dependencies.defaultTitle = longestMonologues;
      dependencies.formatter = formatSecondsToMinutes;

      break;
    case 'customerStories':
      dependencies.shownValueType = 'longest_user_story';
      dependencies.valueType = ' min';
      dependencies.defaultTitle = customerStories;
      dependencies.formatter = formatSecondsToMinutes;

      break;
    case 'interactionTime':
      dependencies.shownValueType = 'total_meeting_time';
      dependencies.valueType = ' hours';
      dependencies.defaultTitle = interactionTime;
      dependencies.formatter = formatMinutesToHours;

      break;
    case 'avgInteractionTime':
      dependencies.shownValueType = 'avg_meeting_time';
      dependencies.valueType = ' min';
      dependencies.defaultTitle = avgInteractionTime;
      dependencies.formatter = formatSecondsToMinutes;

      break;
    case 'customerInTab':
      dependencies.shownValueType = 'meeting_engagement_percentage';
      dependencies.valueType = ' %';
      dependencies.defaultTitle = customerInTab;

      break;
    case 'slidesShown':
      dependencies.shownValueType = 'avg_slides_shown';
      dependencies.valueType = '';
      dependencies.defaultTitle = slidesShown;

      break;
    case 'directShare':
      dependencies.shownValueType = 'direct_share_percentage';
      dependencies.valueType = ' %';
      dependencies.defaultTitle = directShare;

      break;
    case 'faceToFace':
      dependencies.shownValueType = 'face2face_percentage';
      dependencies.valueType = ' %';
      dependencies.defaultTitle = faceToFace;

      break;
    case 'screenShare':
      dependencies.shownValueType = 'screen_share_percentage';
      dependencies.valueType = ' %';
      dependencies.defaultTitle = screenShare;

      break;
    case 'wordsPerMinute':
      dependencies.shownValueType = 'avg_words_per_minute';
      dependencies.valueType = ' word/min';
      dependencies.defaultTitle = wordsPerMinute;

      break;
    case 'callOutcomes':
      dependencies.shownValueType = 'call_outcomes';
      dependencies.valueType = ' meetings';
      dependencies.defaultTitle = callOutcomes;
      dependencies.formatter = roundValue;

      break;
    case 'deals':
      dependencies.shownValueType = 'deals';
      dependencies.valueType = ' meetings';
      dependencies.defaultTitle = deals;
      dependencies.formatter = roundValue;

      break;
    default:
      break;
  }

  return dependencies;
}

const WIDGET_DEPENDENCIES: { [key: string]: Partial<ISentenceWidgetDependencies> } = {
  'sentenceStatsKeyword': { widgetType: STATISTIC_WIDGET_TYPES.KEYWORD, defaultTitle: "Keyword" },
  'sentenceStatsAsyncPromise': { widgetType: STATISTIC_WIDGET_TYPES.ASYNC_PROMISE, defaultTitle: "Promises" },
  'sentenceStatsAsyncUnprofessionalBehaviour': { widgetType: STATISTIC_WIDGET_TYPES.ASYNC_UNPROFESSIONAL_BEHAVIOUR, defaultTitle: "Unprofessional behaviour" },
  'sentiment': { widgetType: STATISTIC_WIDGET_TYPES.SENTIMENT, defaultTitle: 'Sentiment' },
  'sentenceStatsProductIssues': { widgetType: STATISTIC_WIDGET_TYPES.PRODUCT_ISSUES, defaultTitle: 'Customer product issue' },
  'sentenceStatsPainPoints': { widgetType: STATISTIC_WIDGET_TYPES.PAIN_POINTS, defaultTitle: 'Customer concern' },
  'sentenceStatsNewNeeds': { widgetType: STATISTIC_WIDGET_TYPES.NEW_NEEDS, defaultTitle: 'Customer needs' },
  'sentenceStatsPotentialUpselling': { widgetType: STATISTIC_WIDGET_TYPES.POTENTIAL_UPSELLING, defaultTitle: 'Upsell possibility' },
  'sentenceStatsFollowUpActions': { widgetType: STATISTIC_WIDGET_TYPES.FOLLOW_UP_ACTIONS, defaultTitle: 'Follow-up action' },
  'sentenceStatsBuyerObjection': { widgetType: STATISTIC_WIDGET_TYPES.BUYER_OBJECTION, defaultTitle: 'Buyer objection' },
  'sentenceStatsBuyerQuestion': { widgetType: STATISTIC_WIDGET_TYPES.BUYER_QUESTION, defaultTitle: 'Buyer question' },
  'sentenceStatsSellerQuestion': { widgetType: STATISTIC_WIDGET_TYPES.SELLER_QUESTION, defaultTitle: 'Seller question' },
  'sentenceStatsCustomerSentiment': { widgetType: STATISTIC_WIDGET_TYPES.CUSTOMER_SENTIMENT, defaultTitle: 'Buyer emotions' },
  'sentenceStatsImportance': { widgetType: STATISTIC_WIDGET_TYPES.IMPORTANCE, defaultTitle: 'Importance', formatter: roundValue },
  'sentenceStatsTotalQuestion': { widgetType: STATISTIC_WIDGET_TYPES.TOTAL_QUESTION, defaultTitle: 'Total question' },
  'sentenceStatsTotalObjection': { widgetType: STATISTIC_WIDGET_TYPES.TOTAL_OBJECTION, defaultTitle: 'Total objection' },
  'sentenceStatsBuyerPositivity': { widgetType: STATISTIC_WIDGET_TYPES.BUYER_POSITIVITY, defaultTitle: 'Buyer positivity', formatter: formatDecimal },
};

export function getSentenceStatsWidgetDependencies(widgetComponent: string): ISentenceWidgetDependencies {
  let dependencies: ISentenceWidgetDependencies = {
    widgetComponent,
    widgetType: '',
    defaultTitle: '',
    formatter: () => {},
  };

  if (widgetComponent.includes('aiAgent')) {
    dependencies.widgetType = STATISTIC_WIDGET_TYPES.AI_AGENT;
    dependencies.defaultTitle = "AI Agent";
  } else {
    const widgetDependency = WIDGET_DEPENDENCIES[widgetComponent];
    if (widgetDependency) {
      dependencies = { ...dependencies, ...widgetDependency };
    }
  }

  return dependencies;
}


export function getComponentByWidgetType(widgetType: string) {
  let widgetComponent = '';

  switch (widgetType) {
    case STATISTIC_WIDGET_TYPES.KEYWORD:
      widgetComponent = 'sentenceStatsKeyword';
      break;
    case STATISTIC_WIDGET_TYPES.ASYNC_PROMISE:
      widgetComponent = 'sentenceStatsAsyncPromise';
      break;
    case STATISTIC_WIDGET_TYPES.ASYNC_UNPROFESSIONAL_BEHAVIOUR:
      widgetComponent = 'sentenceStatsAsyncUnprofessionalBehaviour';
      break;
    case STATISTIC_WIDGET_TYPES.SENTIMENT:
      widgetComponent = 'sentiment';
      break;
    case STATISTIC_WIDGET_TYPES.PRODUCT_ISSUES:
      widgetComponent = 'sentenceStatsProductIssues';
      break;
    case STATISTIC_WIDGET_TYPES.PAIN_POINTS:
      widgetComponent = 'sentenceStatsPainPoints';
      break;
    case STATISTIC_WIDGET_TYPES.NEW_NEEDS:
      widgetComponent = 'sentenceStatsNewNeeds';
      break;
    case STATISTIC_WIDGET_TYPES.POTENTIAL_UPSELLING:
      widgetComponent = 'sentenceStatsPotentialUpselling';
      break;
    case STATISTIC_WIDGET_TYPES.FOLLOW_UP_ACTIONS:
      widgetComponent = 'sentenceStatsFollowUpActions';
      break;
    case STATISTIC_WIDGET_TYPES.BUYER_OBJECTION:
      widgetComponent = 'sentenceStatsBuyerObjection';
      break;
    case STATISTIC_WIDGET_TYPES.BUYER_QUESTION:
      widgetComponent = 'sentenceStatsBuyerQuestion';
      break;
    case STATISTIC_WIDGET_TYPES.SELLER_QUESTION:
      widgetComponent = 'sentenceStatsSellerQuestion';
      break;
    case STATISTIC_WIDGET_TYPES.CUSTOMER_SENTIMENT:
      widgetComponent = 'sentenceStatsCustomerSentiment';
      break;
    case STATISTIC_WIDGET_TYPES.IMPORTANCE:
      widgetComponent = 'sentenceStatsImportance';
      break;
    case STATISTIC_WIDGET_TYPES.TOTAL_QUESTION:
      widgetComponent = 'sentenceStatsTotalQuestion';
      break;
    case STATISTIC_WIDGET_TYPES.TOTAL_OBJECTION:
      widgetComponent = 'sentenceStatsTotalObjection';
      break;
    case STATISTIC_WIDGET_TYPES.BUYER_POSITIVITY:
      widgetComponent = 'sentenceStatsBuyerPositivity';
      break;
    case STATISTIC_WIDGET_TYPES.AI_AGENT:
      widgetComponent = 'aiAgent';
      break;
    case ANALYTICS_STATS_WIDGET_TYPES.QUESTIONS:
      widgetComponent = 'questions';
      break;
    case ANALYTICS_STATS_WIDGET_TYPES.SLIDES_SHOWN:
      widgetComponent = 'slidesShown';
      break;
    case ANALYTICS_STATS_WIDGET_TYPES.AVG_WORD_PER_MINUTE:
      widgetComponent = 'wordsPerMinute';
      break;
    case ANALYTICS_STATS_WIDGET_TYPES.DIRECT_SHARE_PERCENTAGE:
      widgetComponent = 'directShare';
      break;
    case ANALYTICS_STATS_WIDGET_TYPES.FACE_2_FACE_PERCENTAGE:
      widgetComponent = 'faceToFace';
      break;
    case ANALYTICS_STATS_WIDGET_TYPES.MEETING_SCORE:
      widgetComponent = 'meetingScore';
      break;
    case ANALYTICS_STATS_WIDGET_TYPES.MEETING_ENGAGEMENT_PERCENTAGE:
      widgetComponent = 'customerInTab';
      break;
    case ANALYTICS_STATS_WIDGET_TYPES.SCREEN_SHARE_PERCENTAGE:
      widgetComponent = 'screenShare';
      break;
    case ANALYTICS_STATS_WIDGET_TYPES.DEALS:
      widgetComponent = 'deals';
      break;
    case ANALYTICS_STATS_WIDGET_TYPES.AVG_DIVISION:
      widgetComponent = 'talkListenRatio';
      break;
    case ANALYTICS_STATS_WIDGET_TYPES.AVG_INTERRUPTIONS:
      widgetComponent = 'avgInterruptions';
      break;
    case ANALYTICS_STATS_WIDGET_TYPES.AVG_MEETING_TIME:
      widgetComponent = 'avgInteractionTime';
      break;
    case ANALYTICS_STATS_WIDGET_TYPES.AVG_SHIFTS_PER_MINUTE:
      widgetComponent = 'conversationShifts';
      break;
    case ANALYTICS_STATS_WIDGET_TYPES.LONGEST_MONOLOGUE:
      widgetComponent = 'longestMonologue';
      break;
    case ANALYTICS_STATS_WIDGET_TYPES.LONGEST_USER_STORY:
      widgetComponent = 'customerStories';
      break;
    case ANALYTICS_STATS_WIDGET_TYPES.MEETINGS:
      widgetComponent = 'numberOfInteractions';
      break;
    case ANALYTICS_STATS_WIDGET_TYPES.SCHEDULED_INTERACTIONS:
      widgetComponent = 'scheduledInteractions';
      break;
    case ANALYTICS_STATS_WIDGET_TYPES.TOTAL_MEETING_TIME:
      widgetComponent = 'interactionTime';
      break;
    case ANALYTICS_STATS_WIDGET_TYPES.CALL_OUTCOMES:
      widgetComponent = 'callOutcomes';
      break;
    case AGENT_PLAYBOOK_COMPONENT:
      widgetComponent = AGENT_PLAYBOOK_COMPONENT;
      break;
    case VARIABLE_COMPONENT:
      widgetComponent = VARIABLE_COMPONENT;
    default:
      break;
  }

  return widgetComponent;
}

const widgetTypesByComponent: any = {
  sentenceStatsKeyword: STATISTIC_WIDGET_TYPES.KEYWORD,
  sentenceStatsAsyncPromise: STATISTIC_WIDGET_TYPES.ASYNC_PROMISE,
  sentenceStatsAsyncUnprofessionalBehaviour: STATISTIC_WIDGET_TYPES.ASYNC_UNPROFESSIONAL_BEHAVIOUR,
  sentiment: STATISTIC_WIDGET_TYPES.SENTIMENT,
  sentenceStatsProductIssues: STATISTIC_WIDGET_TYPES.PRODUCT_ISSUES,
  sentenceStatsPainPoints: STATISTIC_WIDGET_TYPES.PAIN_POINTS,
  sentenceStatsNewNeeds: STATISTIC_WIDGET_TYPES.NEW_NEEDS,
  sentenceStatsPotentialUpselling: STATISTIC_WIDGET_TYPES.POTENTIAL_UPSELLING,
  sentenceStatsFollowUpActions: STATISTIC_WIDGET_TYPES.FOLLOW_UP_ACTIONS,
  sentenceStatsBuyerObjection: STATISTIC_WIDGET_TYPES.BUYER_OBJECTION,
  sentenceStatsBuyerQuestion: STATISTIC_WIDGET_TYPES.BUYER_QUESTION,
  sentenceStatsSellerQuestion: STATISTIC_WIDGET_TYPES.SELLER_QUESTION,
  sentenceStatsCustomerSentiment: STATISTIC_WIDGET_TYPES.CUSTOMER_SENTIMENT,
  sentenceStatsImportance: STATISTIC_WIDGET_TYPES.IMPORTANCE,
  sentenceStatsTotalQuestion: STATISTIC_WIDGET_TYPES.TOTAL_QUESTION,
  sentenceStatsTotalObjection: STATISTIC_WIDGET_TYPES.TOTAL_OBJECTION,
  sentenceStatsBuyerPositivity: STATISTIC_WIDGET_TYPES.BUYER_POSITIVITY,
  questions: ANALYTICS_STATS_WIDGET_TYPES.QUESTIONS,
  slidesShown: ANALYTICS_STATS_WIDGET_TYPES.SLIDES_SHOWN,
  wordsPerMinute: ANALYTICS_STATS_WIDGET_TYPES.AVG_WORD_PER_MINUTE,
  directShare: ANALYTICS_STATS_WIDGET_TYPES.DIRECT_SHARE_PERCENTAGE,
  faceToFace: ANALYTICS_STATS_WIDGET_TYPES.FACE_2_FACE_PERCENTAGE,
  meetingScore: ANALYTICS_STATS_WIDGET_TYPES.MEETING_SCORE,
  customerInTab: ANALYTICS_STATS_WIDGET_TYPES.MEETING_ENGAGEMENT_PERCENTAGE,
  screenShare: ANALYTICS_STATS_WIDGET_TYPES.SCREEN_SHARE_PERCENTAGE,
  deals: ANALYTICS_STATS_WIDGET_TYPES.DEALS,
  talkListenRatio: ANALYTICS_STATS_WIDGET_TYPES.AVG_DIVISION,
  avgInterruptions: ANALYTICS_STATS_WIDGET_TYPES.AVG_INTERRUPTIONS,
  avgInteractionTime: ANALYTICS_STATS_WIDGET_TYPES.AVG_MEETING_TIME,
  conversationShifts: ANALYTICS_STATS_WIDGET_TYPES.AVG_SHIFTS_PER_MINUTE,
  longestMonologue: ANALYTICS_STATS_WIDGET_TYPES.LONGEST_MONOLOGUE,
  customerStories: ANALYTICS_STATS_WIDGET_TYPES.LONGEST_USER_STORY,
  numberOfInteractions: ANALYTICS_STATS_WIDGET_TYPES.MEETINGS,
  scheduledInteractions: ANALYTICS_STATS_WIDGET_TYPES.SCHEDULED_INTERACTIONS,
  interactionTime: ANALYTICS_STATS_WIDGET_TYPES.TOTAL_MEETING_TIME,
  callOutcomes: ANALYTICS_STATS_WIDGET_TYPES.CALL_OUTCOMES,
  playbook: AGENT_PLAYBOOK_COMPONENT,
  variable: VARIABLE_COMPONENT,
}

export function getWidgetTypeByComponent(componentName: string): string {
  if (componentName.includes('aiAgent_')) {
    return STATISTIC_WIDGET_TYPES.AI_AGENT;
  }
  return widgetTypesByComponent[componentName] ?? '';
}

export const filterOutUnavailableWidgetOptions = (widgetType: string, widgetOptions: IWidgetOptions) => {
  return produce(widgetOptions, (draftWidgetOptions) => {
    if (draftWidgetOptions.filters.chartType !== 'period') {
      draftWidgetOptions.filters.period = '';
    } else {
      draftWidgetOptions.filters.period = !isEmpty(draftWidgetOptions.filters.period) ? draftWidgetOptions.filters.period : CHART_TYPE_GROUPS.period[1].value;
    }

    if (widgetType !== STATISTIC_WIDGET_TYPES.KEYWORD) {
      draftWidgetOptions.filters.specificStats = [];
      draftWidgetOptions.filters.keywordGroupIds = [];
    }

    if (widgetType === STATISTIC_WIDGET_TYPES.BUYER_POSITIVITY) {
      draftWidgetOptions.filters.denominator = '';
    }

    if (widgetType !== ANALYTICS_STATS_WIDGET_TYPES.CALL_OUTCOMES) {
      draftWidgetOptions.filters.specificCallOutcomes = [];
    }

    if (widgetType !== ANALYTICS_STATS_WIDGET_TYPES.DEALS) {
      draftWidgetOptions.filters.specificDealStatuses = [];
    }
  });
}
