import {
  CREATE_VARIABLE,
  CREATE_VARIABLE_ERROR,
  CREATE_VARIABLE_SUCCESS,
  GET_VARIABLE,
  GET_VARIABLE_ERROR,
  GET_VARIABLE_SUCCESS,
} from './actionTypes';

import { VariableAction, VariableState, } from './interface';

const initialState: VariableState = {
  error: "",
  variables: [],
  isLoading: false,
}

const VariableIntegration = (state = initialState, action: VariableAction) => {
  switch (action.type) {
    case GET_VARIABLE:
    case CREATE_VARIABLE:
      state = {
        ...state,
        isLoading: true,
      }

      break;
    case GET_VARIABLE_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        variables: action.payload,
      }
      break;
    case CREATE_VARIABLE_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        variables: [
          ...state.variables ?? [],
          action.payload,
        ],
      }

      break;
    case GET_VARIABLE_ERROR:
    case CREATE_VARIABLE_ERROR:
      state = {
        ...state,
        isLoading: false,
        error: action.payload
      }

      break;
    default:
      state = {...state};
      break;
  }

  return state;
}

export default VariableIntegration;
