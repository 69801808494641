import axios from 'axios';
import { urls } from '../../utilities/urls';
import { endpoints } from '../../utilities/endpoints';
import { commonheaders } from '../../utilities/apiConfig';

import { Variable, VariableData } from './constants';
import { Filters } from '../../store/layout/interface';
import { IGlobalFilters } from '../../components/Widgets/utils/constants';
import { isEmpty } from 'lodash';

export async function getVariables(search?: string): Promise<VariableData[] | null> {
  const params: any = {};

  if (search) {
    params["variable"] = search;
  }

  try {
    const response = await axios.get(
      `${urls.baseApiURL}${endpoints.variables}`,
      {
        // @ts-ignore
        headers: commonheaders,
        params,
      }
    );
    return response.data?.data ? response.data?.data : null;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function createVariable(variable: Variable): Promise<VariableData | null> {
  try {
    const response = await axios.post(
      `${urls.baseApiURL}${endpoints.variables}`,
      {
        ...variable,
      },
      commonheaders,
    );
    return response.data?.data ? response.data?.data : null;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getVariableStats(
  accountId: String,
  filters: Filters,
  globalFilters: IGlobalFilters,
): Promise<any> {
  const params = new URLSearchParams();

  params.append('group_by', filters.chartType === 'total' ? 'total' : filters.groupBy);

  // TODO: Implement different variable types
  params.append('variable_type', 'boolean');

  if (filters.period) {
    params.append('interval', filters.period);
  }

  if (filters.variableOutputs && filters.variableOutputs.length) {
    filters.variableOutputs.forEach(value => {
      params.append('variable_outputs[]', value);
    })
  }

  if (filters.denominator) {
    params.append('denominator', filters.denominator);
  }

  if (filters.variableIds && filters.variableIds.length > 0) {
    filters.variableIds.forEach((v: number) => {
      params.append('variable_ids[]', String(v))
    });
  }

  for (const [key, value] of Object.entries(globalFilters)) {
    if (key === "global_period") {
      continue;
    }

    if (!isEmpty(value)) {
      if (key === "global_start" || key === "global_end") {
        params.append(key, value?.toString());
      } else if (Array.isArray(value)) {
        value.forEach((item) => {
          params.append(`${key}[]`, item);
        });
      } else {
        params.append(key, value);
      }
    }
  }

  try {
    const response = await axios.get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.stats}/variables`,
      {
        params,
        // @ts-ignore
        headers: commonheaders,
      }
    );

    return response.data?.data ? response.data?.data : null;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}
