import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { History, Location } from "history";
import _ from "lodash";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import { loginGoogleUser, loginBySession } from "../../store/actions";

import success from "../../assets/images/success.svg";
import warning from "../../assets/images/warning.svg";
import {
  isConnectAppOrigin
} from '../../services/partner/partnerService';

type successProps = {
  error: Object | String;
  history: History;
  location: Location;
  loginGoogleUser: (code: string, history: History) => void;
  loginBySession: (sessionId: String, history: History) => void;
};

type successState = {
  errorMessage: String | Object;
};

class GoogleLoginSuccess extends Component<successProps, successState> {
  constructor(props: successProps) {
    super(props);
    this.state = {
      errorMessage: "",
    };
  }

  async componentDidMount() {
    const search = this.props.location.search;
    if (search) {
      const stateCode = new URLSearchParams(search).get("code");
      const sessionId = new URLSearchParams(search).get('session');

      if (isConnectAppOrigin() && !sessionId && !stateCode) {
        console.error('Invalid app url.');

        return;
      } else if (stateCode) {
        this.props.loginGoogleUser(stateCode, this.props.history);
      } else if (sessionId) {
        this.props.loginBySession(sessionId, this.props.history);
      }
    }
  }

  async componentDidUpdate(prevProps: successProps) {
    const { error } = this.props;
    if (!_.isEmpty(error) && error !== prevProps.error) {
      if (typeof error === "object" && !_.isEmpty(error)) {
        this.setState({ errorMessage: Object.values(error)[0] });
      } else {
        this.setState({ errorMessage: error });
      }
    }
  }

  render() {
    const { errorMessage } = this.state;

    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <CardBody className="p-8">
                    <div className="text-center">
                      <div className="succss-avatar-md mx-auto mb-4">
                        <img
                          src={_.isEmpty(errorMessage) ? success : warning}
                          className="succss-img"
                          alt={_.isEmpty(errorMessage) ? "success" : "error"}
                        ></img>
                      </div>

                      <div className="row justify-content-center">
                        <div className="col-xl-10">
                          <h3 className="text-primary">
                            {_.isEmpty(errorMessage) ? "Success !" : "Error !"}
                          </h3>
                          <p className="text-muted font-size-14">
                            {_.isEmpty(errorMessage)
                              ? "Please wait while we redirect you to your homepage"
                              : errorMessage}
                          </p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state: any) => {
  const { error, loading } = state.Login;
  return { error, loading };
};

export default withRouter(
  connect(mapStatetoProps, { loginGoogleUser, loginBySession })(GoogleLoginSuccess)
);
