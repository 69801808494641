import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { History, Location } from "history";
import _ from "lodash";
import { Container, Row, Col, CardBody, Card } from "reactstrap";

import { loginDynamicsUser } from "../../store/actions";
import { removeIntegration } from "../../store/settings/Integration/actions";

//Import Images
import success from "../../assets/images/success.svg";
import warning from "../../assets/images/warning.svg";

import { IntegrationType } from '../../store/settings/Integration/interface';
import {
  getBaseClientUrl,
  isConnectAppOrigin
} from '../../services/partner/partnerService';

type successProps = {
  error: Object | String;
  history: History;
  location: Location;
  loginDynamicsUser: (code: string, history: History) => void;
  removeIntegration: (integrationType: IntegrationType) => void;
};

type successState = {
  errorMessage: String | Object;
};

const ERROR_MESSAGE = "Oops! We encountered an issue.\n" +
    "\n" +
    "Please double-check that you've entered a valid Dynamics CRM URL. \n" +
    "\n" +
    "If you're still having trouble, our support team is here to help. Don't hesitate to reach out! \n" +
    "\n" +
    "You will be redirected to Integrations page shortly... \n";

class Dynamics365Success extends Component<successProps, successState> {
  isTriggered = true;

  constructor(props: successProps) {
    super(props);
    this.state = {
      errorMessage: "",
    };
  }

  async componentDidMount() {
    const search = this.props.location.search;

    const { loginDynamicsUser: loginDynamicsUserAction } = this.props;

    if (search) {
      const stateCode = new URLSearchParams(search).get("code");
      const urlState = new URLSearchParams(search).get("state");

      if (!isConnectAppOrigin()) {
        if (stateCode) {
          loginDynamicsUserAction(stateCode, this.props.history);
        } else {
          this.showErrorAndRedirect();
        }
      } else {
        let redirectBaseUrl = await getBaseClientUrl(urlState);
        window.location.href =`${redirectBaseUrl}/dynamics/success${search}`;
      }
    }
  }

  showErrorAndRedirect = (errorMessage = ERROR_MESSAGE) => {
    this.setState({
      errorMessage: ERROR_MESSAGE,
    }, () => {
      this.redirectOnErrorWithTimeout();
    })
  }

  redirectOnErrorWithTimeout = (timeout = 8000) => {
    this.props.removeIntegration('dynamics365');

    setTimeout(() => {
      this.setState({
        errorMessage: '',
      }, () => {
        this.props.history.push('/settings/integrations')
      })
    }, timeout);
  }

  componentDidUpdate(prevProps: successProps) {
    const { error } = this.props;
    if (!_.isEmpty(error) && error !== prevProps.error) {
      if (typeof error === "object" && !_.isEmpty(error)) {
        this.setState({ errorMessage: Object.values(error)[0] });
      } else {
        this.setState({ errorMessage: error });
      }
    }
  }

  render() {
    const { errorMessage } = this.state;
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <CardBody className="p-8">
                    <div className="text-center">
                      <div className="succss-avatar-md mx-auto mb-4">
                        <img
                          src={_.isEmpty(errorMessage) ? success : warning}
                          className="succss-img"
                          alt={_.isEmpty(errorMessage) ? "success" : "error"}
                        ></img>
                      </div>

                      <div className="row justify-content-center">
                        <div className="col-xl-10">
                          <h3
                            className="text-primary"
                          >
                            {_.isEmpty(errorMessage) ? "Success !" : "Error !"}
                          </h3>
                          <p className="text-muted font-size-14" style={{
                            textAlign: errorMessage ? 'justify' : 'center',
                          }}>
                            {_.isEmpty(errorMessage)
                              ? "Success! Your Dynamics account is now connected to Meetric.\n" +
                              "\n" +
                              "We're preparing your personalized integrations page.\n" +
                              "Please wait a moment while we redirect you..."
                              : errorMessage}
                          </p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state: any) => {
  const { error, loading } = state.Login;
  return { error, loading };
};

export default withRouter(
  connect(mapStatetoProps, { loginDynamicsUser, removeIntegration })(Dynamics365Success)
);
