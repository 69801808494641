export const stagingURL = "https://dashboard.staging.pitchflow.io";
export const acceptFlowURL = "https://app.acceptflow.com";
export const acceptStackURL = "https://app.acceptstack.com";
export const devioneStagingURL = 'https://dash.devioneprojects.com'
export const meetricURL = "https://app.meetric.com";
export const pitchflowURL = "https://app.pitchflow.com";
export const localURL = "http://localhost:3000";

export const connectAppURL = 'https://v1.connectapplications.com';
export const connectAppStagingURL = 'https://connectapplications.acceptstack.com';
export const connectAppDevURL = 'https://connectapplications.acceptflow.com';
export const connectAppLocalURL = 'http://localhost:3000';

export const isStagingURL = window.location.origin === stagingURL;
export const isStagingConnectURL = window.location.origin === connectAppStagingURL;

export const isAcceptFlowURL = window.location.origin === acceptFlowURL;
export const isAcceptFlowConnectURL = window.location.origin === connectAppDevURL;

export const isAcceptStackURL = window.location.origin === acceptStackURL;
export const isAcceptStackConnectURL = window.location.origin === connectAppStagingURL;

export const isMeetricURL = window.location.origin === meetricURL;
export const isMeetricConnectURL = window.location.origin === connectAppURL;

export const isPitchflowURL = window.location.origin === pitchflowURL;

export const isDevioneStagingURL = window.location.origin === devioneStagingURL;

export const isLocalURL = window.location.origin === localURL;
export const isLocalConnectURL = window.location.origin === connectAppLocalURL;

let paths = {
    editorURL: "",
    baseApiURL: "",
    baseIframeURL: "",
    baseURL: "",
    loginUrl: "",
};

if (isStagingURL) {
    paths = {
        editorURL: "https://presentation.acceptflow.com/editor",
        baseApiURL: "https://api.acceptflow.com/api/",
        baseIframeURL: "https://presentation.acceptflow.com/presentation/preview",
        baseURL: "https://presentation.acceptflow.com",
        loginUrl: "/login",
    }
} else if (isAcceptFlowURL || isAcceptFlowConnectURL) {
    paths = {
        editorURL: "https://presentation.acceptflow.com/editor",
        baseApiURL: "https://api.acceptflow.com/api/",
        baseIframeURL: "https://presentation.acceptflow.com/presentation/preview",
        baseURL: "https://presentation.acceptflow.com",
        loginUrl: "/login",
    }
}
else if (isAcceptStackURL || isAcceptStackConnectURL || isDevioneStagingURL) {
    paths = {
        editorURL: "https://presentation.acceptstack.com/editor",
        baseApiURL: "https://api.acceptstack.com/api/",
        baseIframeURL: "https://presentation.acceptstack.com/presentation/preview",
        baseURL: "https://presentation.acceptstack.com",
        loginUrl: "/login",
    }
} else if (isPitchflowURL) {
    document.location = document
        .location
        .href
        .replace("pitchflow.com","meetric.com");
} else if (isLocalURL || isLocalConnectURL) {
    paths = {
        editorURL: "https://presentation.acceptflow.com/editor",
        baseApiURL: "http://localhost:8000/api/",
        baseIframeURL: "https://presentation.acceptflow.com/presentation/preview",
        baseURL: "https://presentation.acceptflow.com",
        loginUrl: "/login",
    }
} else {
    paths = {
        editorURL: "https://presentation.meetric.com/editor",
        baseApiURL: "https://api.meetric.com/api/",
        baseIframeURL: "https://presentation.meetric.com/presentation/preview",
        baseURL: "https://presentation.meetric.com",
        loginUrl: "/login",
    }
}

export const urls = {
    editorURL: paths.editorURL,
    baseApiURL: paths.baseApiURL,
    baseIframeURL: paths.baseIframeURL,
    baseURL: paths.baseURL,
    loginUrl: paths.loginUrl,
}
