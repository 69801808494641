import { ITranscriptItem } from '../../../store/interface';
import { IAIAgentPreviewTabs, OptionType } from './interface';

export const typeOptions: OptionType[] = [
  { label: 'Meeting summary', value: 'meeting_summary' },
  { label: 'Data Extractor', value: 'data_extractor' },
  { label: 'Playbook', value: 'playbook' },
  { label: 'Magic Data Extractor', value: 'data_insights' },
];

export enum Type {
  MeetingSummary = 'meeting_summary',
  Playbook = 'playbook',
  GetData = 'data_extractor',
  DataInsights = 'data_insights',
}

export const wordCountInputTypes = [Type.MeetingSummary];
export const sampleInputTypes = [Type.MeetingSummary, Type.Playbook, Type.GetData];
export const languageInputTypes = [Type.MeetingSummary, Type.Playbook, Type.GetData, Type.DataInsights];

export enum AIAgentStatus {
  Active = 'active',
  Draft = 'draft',
  Paused = 'pause',
}

export enum AIAgentRequestStatus {
  Queued = 'queued',
  Streaming = 'streaming',
  Completed = 'completed',
  Error = 'error',
}

export const languageOptions: OptionType[] = [
  { label: 'English', value: 'en' },
  { label: 'Swedish', value: 'sv' },
  { label: 'Danish', value: 'da' },
  { label: 'Finnish', value: 'fi' },
  { label: 'German', value: 'de' },
  { label: 'Spanish', value: 'es' },
  { label: 'Same as transcript', value: 'same_as_transcript' },
];

export const wordCountOptions: OptionType[] = [
  { label: 'Not set', value: null },
  { label: '100 words', value: '100' },
  { label: '250 words', value: '250' },
  { label: '500 words', value: '500' },
  { label: '750 words', value: '750' },
];

export const defaultAIAgent = {
  name: '',
  type: '',
  customPrompt: '',
  globalFilters: [],
  typeSettings: {},
  status: AIAgentStatus.Draft,
};

export const errorFromAI = 'An error occurred while fetching the AI response.';

export const getAIAgentStatus = (value: string): AIAgentStatus | undefined => {
  switch (value) {
    case AIAgentStatus.Active:
      return AIAgentStatus.Active;
    case AIAgentStatus.Draft:
      return AIAgentStatus.Draft;
    case AIAgentStatus.Paused:
      return AIAgentStatus.Paused;
    default:
      return undefined;
  }
};

export const archiveAgentConfirmationText =
  'Are you certain you want to archive this agent? Once archived, the agent will no longer be visible.';
export const activeStatusConfirmationText =
  'Are you sure you want to activate this agent? Activating the agent will enable automatic processing and analysis of your meetings.';
export const pauseStatusConfirmationText =
  'Are you sure you want to deactivate this agent? Deactivating the agent will stop the automatic processing and analysis of your meetings.';
export const activateAgentText = 'Deactivated';
export const deactivateAgentText = 'Activated';

export const tabNames = {
  [IAIAgentPreviewTabs.Results]: 'Preview results',
  [IAIAgentPreviewTabs.Transcript]: 'Preview transcript',
};

export const findStartTime = (transcriptArray: ITranscriptItem[], sentenceRange: string) => {
  const [startSentence] = sentenceRange.split('-').map(Number);

  for (let i = 0; i < transcriptArray.length; i++) {
    if (i + 1 === startSentence) {
      return transcriptArray[i].start;
    }
  }

  return null;
};

export const isPlaybookOrInsightsType = (type: any) => {
  return type === Type.Playbook || type === Type.DataInsights;
}

export const playbook = 'playbook';

export interface QuestionOutputOption {
  id: number,
  label: string,
  value: string,
}

export const QuestionOutputTypeDropdown: QuestionOutputOption[] = [
  {
    id: 1,
    label: 'Text',
    value: 'text',
  }, {
    id: 2,
    label: 'Number',
    value: 'number',
  }, {
    id: 3,
    label: 'True/False',
    value: 'boolean',
  }, {
    id: 4,
    label: 'One of options',
    value: 'list',
  },
];

export const findOutputOptionValue = (value: string | undefined | null): string => {
  const option = QuestionOutputTypeDropdown.find((option: QuestionOutputOption) => option.value === value);

  return option?.value ?? QuestionOutputTypeDropdown[0].value;
}

export const findOutputOption = (value: string | undefined | null): QuestionOutputOption => {
  const option = QuestionOutputTypeDropdown.find((option: QuestionOutputOption) => option.value === value);

  return option ?? QuestionOutputTypeDropdown[0];
}

export const isLineReferenceColumn = (text: string) => {
    const trimmedLowerCase = text.trim().toLowerCase();

    return trimmedLowerCase === 'line reference' || trimmedLowerCase === 'mentioned at';
}
